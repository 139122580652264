import { mapGetters, mapState } from 'vuex'
import ozRecommendations from '@/components/oz-recommendations'
import ozLoading from '@/components/oz-loading'
import mapper from './profile.mapper'

export default {
  name: 'oz-user-recommendations',

  data () {
    return {
      recommendations: [],
    }
  },

  props: {
    source: { type: String, required: true },
    quantity: { type: Number, default: 12 },
    slots: { type: Number, default: 1 },
    listingId: { type: String },
    filters: { type: Object },
    expandedCta: { type: Boolean, default: false },
    // for tracking purposes
    businessTypeContext: { type: String },
    settings: { type: Object },
    screen: { type: String },
    howRefPage: { type: String },
  },

  computed: {
    ...mapState({
      glossary: (state) => state.context.glossary,
      loading: (state) => !state.recommendations || state.recommendations.loading,
    }),

    ...mapGetters({
      location: 'recommendations/location',
      currentPlatform: 'currentPlatform',
    }),

    profile () {
      return this.filters ? mapper(this.filters, this.glossary) : {}
    },

    parameters () {
      const {
        source, quantity, slots, listingId: listing, profile,
      } = this
      return {
        source, quantity, slots, listing, ...profile,
      }
    },

    latLon () {
      const { latitude = '', longitude = '' } = this.location ?? {}

      return `${latitude}${longitude}`
    },
  },

  mounted () {
    this.init(true)
  },

  watch: {
    latLon (newValue) {
      if (newValue) {
        this.reset()
        this.init(false)
      }
    },

    filters (newValue, oldValue) {
      const notFiltredByQuery = !newValue?.q || (newValue?.q === oldValue?.q)

      if (newValue && notFiltredByQuery) {
        this.reset()
        this.init(false)
      }
    },
  },

  methods: {
    async init (reloadLocation) {
      if (reloadLocation) {
        await this.$store.dispatch('recommendations/fetchUserLocation')
      }

      await this.$store.dispatch('recommendations/fetchRecommendations', this.parameters)
      this.recommendations = this.$store.getters['recommendations/recommendations']
    },

    reset () {
      this.recommendations = []
      this.$refs.recommendationsListings?.reset()
    },
  },

  components: {
    ozLoading,
    ozRecommendations,
  },
}
