import Vue from 'vue'

const interestShowcaseClicked = ({ link, screen = 'HOME PAGE', subject }) => {
  const data = {
    interestShowcase: {
      link,
      screen,
      subject,
    },
    event: 'interestShowcaseClicked',
  }

  Vue.$clickstream.trackEvent(data)
}

export default {
  interestShowcaseClicked,
}
