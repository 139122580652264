import { render, staticRenderFns } from "./oz-zap-magazine.html?vue&type=template&id=162c4535&"
import script from "./oz-zap-magazine.js?vue&type=script&lang=js&"
export * from "./oz-zap-magazine.js?vue&type=script&lang=js&"
import style0 from "./oz-zap-magazine.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports