import { marked } from 'marked'

export default {
  name: 'new-hero',

  props: {
    hero: {
      type: Object,
    },
    filterPosition: {
      type: String,
      default: 'right',
    },
  },

  data () {
    return {
      altImage: this.hero.altImage,
    }
  },

  computed: {
    image () {
      try {
        return this.hero.image.formats.large
      } catch {
        return this.hero.image
      }
    },

    title () {
      return marked(this.hero.h2)
    },

    subtitle () {
      return marked(this.hero.h3)
    },

    customClass () {
      return {
        'new-hero--left': this.filterPosition === 'left',
        'new-hero--right': this.filterPosition === 'right',
      }
    },
  },
}
