export const adsConfigSlotReady = {
  ad: {
    special_home: 'homeAdSpecial',
    in_between_home: 'homeAdInBetweenHome',
    near_bottom_home: 'homeAdNearBottom',
    bottom_home: 'homeAdBottom',
  },
  event: 'homeAdSlotReady',
  _clear: true,
}

export const adElementIds = Object.values(adsConfigSlotReady.ad)
