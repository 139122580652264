import apiClient from './client'
import createGluePayload from './utils/glue'
import {
  createParamsUserInterestPayload,
  createBodyUserInterestPayload,
} from './utils/user-interest'

const DEFAULT_SOURCE = 'zap'

export async function getUserFavorites (endpoint, {
  token, xDeviceId, domain, images,
}) {
  const url = `${endpoint}/v2/favorites`

  const payload = createGluePayload({
    authorization: token,
    xDeviceId,
    domain,
    source: DEFAULT_SOURCE,
    images,
  })

  return apiClient.get(url, payload)
}

export async function addUserFavorite (endpoint, {
  id, isDevelopment, token, xDeviceId,
}) {
  const url = `${endpoint}/favorites`

  const { data, ...config } = createBodyUserInterestPayload({
    authorization: token,
    xDeviceId,
    listing: {
      id,
      category: isDevelopment ? 'DEVELOPMENT' : 'PROPERTY',
    },
    source: DEFAULT_SOURCE,
  })

  return apiClient.post(url, data, config)
}

export async function removeUserFavorite (endpoint, {
  id: listingId, isDevelopment, token, xDeviceId,
}) {
  const category = isDevelopment ? 'DEVELOPMENT' : 'PROPERTY'
  const url = `${endpoint}/${category}/${listingId}/favorite`

  const payload = createParamsUserInterestPayload({
    authorization: token,
    xDeviceId,
    listing: {
      id: listingId,
      category,
    },
    source: DEFAULT_SOURCE,
  })

  await apiClient.delete(url, payload)
}
