import { icVerified } from '@vivareal/lina-icons'
import ozDatazapStamp from '@/components/oz-datazap-stamp'

const cardPricesMixin = {
  props: {
    businessTypeContext: { type: String },
    pricingInfo: { type: Object, required: true },
    datazapApproved: { type: Boolean, default: false },
  },

  data () {
    return {
      icons: {
        icVerified,
      },
    }
  },

  computed: {
    prices () {
      if (!this.businessTypeContext) return this.pricingInfo

      const { priceMap } = this.pricingInfo

      return priceMap[this.businessTypeContext]
    },
  },

  components: {
    ozDatazapStamp,
  },
}

export default cardPricesMixin
