import { LOCATIONS_IMAGES, UNIT_TYPES } from '@/utils/constants'

const {
  SP,
  RJ,
  MG,
  RS,
  PR,
} = LOCATIONS_IMAGES

export const FILTERS = {
  business: 'SALE',
  unitTypes: UNIT_TYPES.UNIT_TYPE_DEFAULT,
  listingType: 'USED',
  addresses: [],
  portal: 'ZAP',
  categoryPage: 'RESULT',
}

export const STATIC_SEO = [
  {
    heading: 'h2',
    header: {
      name: 'Buscas mais populares',
      rel: '',
    },
    links: [
      {
        name: 'Apartamentos para vender em São Paulo',
        href: '/venda/apartamentos/sp+sao-paulo/',
      },
      {
        name: 'Venda em São Paulo de casas',
        href: '/venda/casas/sp+sao-paulo/',
      },
      {
        name: 'Quitinetes em São Paulo para alugar',
        href: '/aluguel/quitinetes/sp+sao-paulo/',
      },
      {
        name: 'Apartamentos à venda em Belo Horizonte',
        href: '/venda/apartamentos/mg+belo-horizonte/',
      },
      {
        name: 'Apartamentos para vender em Santos',
        href: '/venda/apartamentos/sp+santos/',
      },
      {
        name: 'Apartamentos à venda em Curitiba',
        href: '/venda/apartamentos/pr+curitiba/',
      },
      {
        name: 'Apartamentos para alugar em Curitiba',
        href: '/aluguel/apartamentos/pr+curitiba/',
      },
      {
        name: 'Venda apartamentos em Porto Alegre',
        href: '/venda/apartamentos/rs+porto-alegre/',
      },
      {
        name: 'Apartamentos à venda em Rio de Janeiro',
        href: '/venda/apartamentos/rj+rio-de-janeiro/',
      },
      {
        name: 'Apartamentos para alugar em Santos',
        href: '/aluguel/apartamentos/sp+santos/',
      },
      {
        name: 'Apartamentos para vender em Praia Grande',
        href: '/venda/apartamentos/sp+praia-grande/',
      },
      {
        name: 'Apartamentos para alugar em Rio de Janeiro',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro/',
      },
      {
        name: 'Venda apartamentos em Recreio dos Bandeirantes',
        href: '/venda/apartamentos/rj+rio-de-janeiro+zona-oeste+recreio-dos-bandeirantes/',
      },
      {
        name: 'Apartamentos para alugar em Recreio dos Bandeirantes',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro+zona-oeste+recreio-dos-bandeirantes/',
      },
      {
        name: 'Venda apartamentos em Barra da Tijuca',
        href: '/venda/apartamentos/rj+rio-de-janeiro+zona-oeste+barra-da-tijuca/',
      },
      {
        name: 'Apartamentos à venda em Fortaleza',
        href: '/venda/apartamentos/ce+fortaleza/',
      },
      {
        name: 'Apartamentos para vender em São Bernardo do Campo',
        href: '/venda/apartamentos/sp+sao-bernardo-do-campo/',
      },
      {
        name: 'Apartamentos para alugar em São Paulo',
        href: '/aluguel/apartamentos/sp+sao-paulo/mobiliado/',
      },
      {
        name: 'Apartamentos para alugar em Porto Alegre',
        href: '/aluguel/apartamentos/rs+porto-alegre/direto-com-proprietario/',
      },
      {
        name: 'Apartamentos à venda em Recife',
        href: '/venda/apartamentos/pe+recife/',
      },
      {
        name: 'Venda apartamentos em Copacabana',
        href: '/venda/apartamentos/rj+rio-de-janeiro+zona-sul+copacabana/',
      },
      {
        name: 'Apartamentos para vender em Jundiaí',
        href: '/venda/apartamentos/sp+jundiai/',
      },
      {
        name: 'Apartamentos para alugar em Salvador',
        href: '/aluguel/apartamentos/ba+salvador/',
      },
      {
        name: 'Apartamentos para alugar em Fortaleza',
        href: '/aluguel/apartamentos/ce+fortaleza/',
      },
      {
        name: 'Apartamentos para alugar em Balneario Camboriú',
        href: '/aluguel/apartamentos/sc+balneario-camboriu/',
      },
      {
        name: 'Venda em casas Guarulhos',
        href: '/venda/casas/sp+guarulhos/',
      },
      {
        name: 'Apartamentos para alugar em Florianópolis',
        href: '/aluguel/apartamentos/sc+florianopolis/',
      },
      {
        name: 'Casas em Florianópolis para vender',
        href: '/venda/casas/sc+florianopolis/',
      },
      {
        name: 'Alugar apartamentos em Pinheiros',
        href: '/aluguel/apartamentos/sp+sao-paulo+zona-oeste+pinheiros/',
      },
      {
        name: 'Apartamentos para vender em Santo André',
        href: '/venda/apartamentos/sp+santo-andre/',
      },
      {
        name: 'Apartamentos à venda em Salvador',
        href: '/venda/apartamentos/ba+salvador/',
      },
      {
        name: 'Quitinetes em Belo Horizonte para alugar',
        href: '/aluguel/quitinetes/mg+belo-horizonte/',
      },
      {
        name: 'Apartamentos para alugar em Barra da Tijuca',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro+zona-oeste+barra-da-tijuca/',
      },
      {
        name: 'Apartamentos à venda em Goiania',
        href: '/venda/apartamentos/go+goiania/',
      },
      {
        name: 'Apartamentos para vender em Guarulhos',
        href: '/venda/apartamentos/sp+guarulhos/',
      },
      {
        name: 'Quitinetes em Rio de Janeiro para alugar',
        href: '/aluguel/quitinetes/rj+rio-de-janeiro/',
      },
      {
        name: 'Alugar casas em Curitiba',
        href: '/aluguel/casas/pr+curitiba/',
      },
      {
        name: 'Lançamentos de apartamentos em São Paulo',
        href: '/lancamentos/apartamentos/sp+sao-paulo/',
      },
      {
        name: 'Venda apartamentos em Guaruja',
        href: '/venda/apartamentos/sp+guaruja/',
      },
      {
        name: 'Apartamentos para alugar em Goiania',
        href: '/aluguel/apartamentos/go+goiania/',
      },
      {
        name: 'Venda apartamentos em Florianopolis',
        href: '/venda/apartamentos/sc+florianopolis/',
      },
      {
        name: 'Apartamentos para alugar em Tijuca',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro+zona-norte+tijuca/',
      },
      {
        name: 'Casas de condominio em São Paulo à venda',
        href: '/venda/casas-de-condominio/sp+sao-paulo/',
      },
      {
        name: 'Alugar flat em São Paulo',
        href: '/aluguel/flat/sp+sao-paulo/',
      },
      {
        name: 'Casas em Curitiba para vender',
        href: '/venda/casas/pr+curitiba/',
      },
    ],
  },
]

export const CITIES_SEO = [
  {
    links: [
      {
        name: 'São Paulo',
        href: '/imoveis/sp+sao-paulo/',
      },
      {
        name: 'Campinas',
        href: '/imoveis/sp+campinas/',
      },
      {
        name: 'São Bernardo do Campo',
        href: '/imoveis/sp+sao-bernardo-do-campo/',
      },
      {
        name: 'Santo André',
        href: '/imoveis/sp+santo-andre/',
      },
      {
        name: 'Sao Jose dos Campos',
        href: '/imoveis/sp+sao-jose-dos-campos/',
      },
    ],
    header: {
      name: 'São Paulo',
      imageUrl: SP,
    },
  },
  {
    links: [
      {
        name: 'Rio de Janeiro',
        href: '/imoveis/rj+rio-de-janeiro/',
      },
      {
        name: 'Niterói',
        href: '/imoveis/rj+niteroi/',
      },
      {
        name: 'São Gonçalo',
        href: '/imoveis/rj+sao-goncalo/',
      },
      {
        name: 'Duque de Caxias',
        href: '/imoveis/rj+duque-de-caxias/',
      },
      {
        name: 'Nova Iguaçu',
        href: '/imoveis/rj+nova-iguacu/',
      },
    ],
    header: {
      name: 'Rio de Janeiro',
      imageUrl: RJ,
    },
  },
  {
    links: [
      {
        name: 'Belo Horizonte',
        href: '/imoveis/mg+belo-horizonte/',
      },
      {
        name: 'Uberlândia',
        href: '/imoveis/mg+uberlandia/',
      },
      {
        name: 'Contagem',
        href: '/imoveis/mg+contagem/',
      },
      {
        name: 'Juiz de Fora',
        href: '/imoveis/mg+juiz-de-fora/',
      },
      {
        name: 'Betim',
        href: '/imoveis/mg+betim/',
      },
    ],
    header: {
      name: 'Minas Gerais',
      imageUrl: MG,
    },
  },
  {
    links: [
      {
        name: 'Porto Alegre',
        href: '/imoveis/rs+porto-alegre/',
      },
      {
        name: 'Canoas',
        href: '/imoveis/rs+canoas',
      },
      {
        name: 'Caxias do Sul',
        href: '/imoveis/rs+caxias-do-sul/',
      },
      {
        name: 'Pelotas',
        href: '/imoveis/rs+pelotas',
      },
      {
        name: 'Novo Hamburgo',
        href: '/imoveis/rs+novo-hamburgo/',
      },
    ],
    header: {
      name: 'Rio Grande do Sul',
      imageUrl: RS,
    },
  },
  {
    links: [
      {
        name: 'Curitiba',
        href: '/imoveis/pr+curitiba/',
      },
      {
        name: 'Londrina',
        href: '/imoveis/pr+londrina/',
      },
      {
        name: 'Maringá',
        href: '/imoveis/pr+maringa/',
      },
      {
        name: 'São José dos Pinhais',
        href: '/imoveis/pr+sao-jose-dos-pinhais/',
      },
      {
        name: 'Foz do Iguaçu',
        href: '/imoveis/pr+foz-do-iguacu/',
      },
    ],
    header: {
      name: 'Paraná',
      imageUrl: PR,
    },
  },
]

export const CAMPAIGN_INFO = {
  CAMPAIGN_NAME_PATTERN: /undefined/i,
  REGION_PATTERN: /^undefined$/i,
  CITY_PATTERN: /^undefined$/i,
}
