import { icStar, icStarFilled } from '@vivareal/lina-icons'
import { mapState } from 'vuex'

const quality = {
  data () {
    return {
      icons: {
        icStar,
        icStarFilled,
      },
    }
  },
  computed: {
    ...mapState({
      attendanceQualityInfo: (state) => state.enem.attendanceQualityInfo,
      isLoading: (state) => state.enem.isLoading,
    }),
    adsDescription () {
      return 'Esse anunciante geralmente responde todos os contatos que são solicitados'
    },
    showAnsweredRateGreaterThan80 () {
      if (this.attendanceQualityInfo) {
        const { answeredLeads, totalFeedbacks } = this.attendanceQualityInfo
        const result = parseFloat((answeredLeads / totalFeedbacks) * 100).toFixed(0)
        return result > 80
      }
      return false
    },
    totalRatings () {
      if (this.attendanceQualityInfo) {
        const { totalRatings } = this.attendanceQualityInfo
        return totalRatings
      }
      return null
    },
    totalRatingsText () {
      const text = 'classificações'
      if (this.attendanceQualityInfo) {
        return this.totalRatings > 1 ? `${this.totalRatings} ${text}` : `${this.totalRatings} classificação`
      }
      return `0 ${text}`
    },
    score () {
      if (this.attendanceQualityInfo) {
        const { stars } = this.attendanceQualityInfo
        return parseFloat(stars).toFixed(1)
      }
      return '0'
    },
    totalFeedbacksText () {
      const text = 'avaliações recebidas'
      if (this.attendanceQualityInfo) {
        const { totalFeedbacks } = this.attendanceQualityInfo
        return totalFeedbacks > 1 ? `${totalFeedbacks} ${text}` : `${totalFeedbacks} avaliação recebida`
      }
      return `0 ${text}`
    },
    shortTotalFeedbacksText () {
      const text = 'avaliações'
      if (this.attendanceQualityInfo?.totalFeedbacks) {
        const { totalFeedbacks } = this.attendanceQualityInfo
        return totalFeedbacks > 1 ? `${totalFeedbacks} ${text}` : `${totalFeedbacks} avaliação`
      }
      return ''
    },
    ratingStars () {
      const ratings = [
        { id: 1, icon: this.icons.icStar },
        { id: 2, icon: this.icons.icStar },
        { id: 3, icon: this.icons.icStar },
        { id: 4, icon: this.icons.icStar },
        { id: 5, icon: this.icons.icStar },
      ]
      const roundScore = parseInt(this.score, 10)
      ratings.forEach((star) => {
        if (star.id <= roundScore) {
          star.icon = this.icons.icStarFilled
        }
      })
      return ratings
    },
    scoreText () {
      return this.score.toString().replace('.', ',')
    },
  },
}
export default quality
