import { mapState } from 'vuex'
import getUrl from '@/mixins/get-url'
import registerStoreModuleMixin from '@/mixins/register-store-module'
import optimizeActivate from '@/mixins/optimize-activate'

import tracker from '@/utils/tracking/tracker'
import { getQueryByParams } from '@/utils/urls/mapper'

import ozSeoWidgetsLinks from '@/components/seo/oz-seo-widgets-links'
import ozSeoWidgetsNeighborhoods from '@/components/seo/oz-seo-widgets-neighborhoods'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import ozUserRecommendations from '@/components/oz-user-recommendations'
import newHero from './components/new-hero'
import ozInterestsShowcase from './components/oz-interests-showcase'
import newFilters from './components/new-filters'
import newAdvertiseProperty from './components/new-advertise-property'
import recommendationsSettings from './oz-recommendations.settings'
import trackingHelper from './tracking'

import {
  STATIC_SEO,
} from './constants'

import ozZapMagazine from './components/oz-zap-magazine'

const ozAds = hydrateWhenVisible(
  () => import(/* webpackChunkName: "oz-ads" */'@/components/oz-ads'),
  { observerOptions: { rootMargin: '320px' } },
)

const ozMainLocations = hydrateWhenVisible(
  () => import(/* webpackChunkName: "oz-main-locations" */'@/components/seo/oz-main-locations-poc'),
  { observerOptions: { rootMargin: '320px' } },
)

const isProd = process.env.NODE_ENV === 'production'

export default {
  name: 'home',

  mixins: [
    registerStoreModuleMixin,
    optimizeActivate,
    getUrl,
  ],

  data () {
    return {
      loading: false,
      widgets: STATIC_SEO,
      recommendationsSettings,
    }
  },

  computed: {
    ...mapState({
      path: (state) => state.page.path,
      glossary: (state) => state.context.glossary,
      unitTypeGlossary: (state) => state.context.unitTypeGlossary,
      userLocation: (state) => state.userLocation.userLocation,
      cmsContent: (state) => state.home.homePageContent,
      neighborhoodsStates: (state) => state.home.neighborhoodsStates,
      borderLocation: (state) => state.context.borderLocation,
    }),
  },

  // SERVER ONLY
  asyncData: async ({ store, route }) => {
    await store.dispatch('home/fetchCMSContent', route.query)
    await store.dispatch('home/gettingNeighborhoodsStates', route.query)
  },

  beforeRouteEnter (to, from, next) {
    if (__BROWSER__) {
      if (isProd && (from.name === 'listing' || from.name === 'results')) {
        window.location = '/'
      }

      next()
    } else {
      next()
    }
  },

  methods: {
    async search (filters) {
      this.loading = true
      window.skipAsyncDataRP = true

      filters.portal = 'ZAP'
      filters.categoryPage = 'RESULT'

      await this.$store.dispatch('results/search', filters)

      this.$router.push({
        path: this.path,
        query: {
          ...this.$getUrl.query,
          ...getQueryByParams(this.filters, this.glossary, this.unitTypeGlossary),
        },
      })
    },

    initTrackers (from) {
      tracker.trackPageView(this.$store, trackingHelper.pageView)
      tracker.trackPageRendered()
      tracker.track(trackingHelper.adsConfigSlotReady)
      // avoid adsRefreshed on first render cause GTM initialization tag will handle that
      if (from.name) {
        tracker.trackAdsRefreshed(trackingHelper.adElementIds)
      }
    },

    isValidCmsHomePageContent (content) {
      const necessaryCollections = [
        'zapHomePageHeaderCollection',
        'zapHomePageShowcaseCollection',
        'zapHomePageBannerCollection',
        'zapHomePageZapBlogCollection',
      ]
      const contentCollections = Object.keys(content)
      const containAllColections = necessaryCollections
        .every((collection) => contentCollections.includes(collection))
      return containAllColections
    },
  },

  components: {
    newHero,
    ozAds,
    newFilters,
    ozUserRecommendations,
    ozSeoWidgetsLinks,
    ozSeoWidgetsNeighborhoods,
    ozZapMagazine,
    newAdvertiseProperty,
    ozMainLocations,
    ozInterestsShowcase,
  },
}
