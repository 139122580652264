import Vue from 'vue'

const buildNeighborhoodsGuideLinkViewEvent = (subject, position) => ({
  neighborhoodsGuideLink: {
    subject, position,
  },
  event: 'neighborhoodsGuideInternalLinkView',
  _clear: true,
})

export default {
  sectionViewed (subject, position) {
    const event = buildNeighborhoodsGuideLinkViewEvent(subject, position)
    Vue.$clickstream.trackEvent(event)
  },
}

export { buildNeighborhoodsGuideLinkViewEvent }
