import { mapGetters } from 'vuex'
import apiClient from '@/api/client'
import ozAds from '@/components/oz-ads'

import ozZapMagazinePostList from '../oz-zap-magazine-post-list'

export default {

  mounted () {
    this.getPosts()
  },

  data () {
    return {
      posts: [],
      loading: true,
      perPage: 3,
    }
  },

  props: {
    blogContent: { type: Object, required: true },
  },

  computed: {
    ...mapGetters(['isDesktop']),
  },

  methods: {
    async getPosts () {
      try {
        const { data } = await apiClient
          .get(`//www.zapimoveis.com.br/blog/wp-json/wp/v2/posts?_embed&per_page=${this.perPage}&page=1`)

        this.posts = data.map(({ title, link }) => ({
          title: title.rendered,
          link,
        }))
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },

  components: {
    ozAds,
    ozZapMagazinePostList,
  },
}
