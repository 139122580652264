import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'oz-ads',
  props: {
    tag: { type: String, default: 'aside' },
    trackId: { type: String, required: true },
    trackClick: { type: Object, required: false },
    gutter: { type: [String, Object] },
  },

  methods: {
    onClick (event) {
      if (!isEmpty(this.trackClick) && event.target.id !== this.trackId) {
        // TODO: TEST PENDING
        Vue.$clickstream.trackEvent(this.trackClick)
      }
    },
  },
}
