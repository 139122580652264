const setFavorites = (state, rawFavorites) => {
  const favorites = {}
  rawFavorites.forEach(({ listing }) => {
    favorites[listing.id || listing.listing.id] = true
  })

  state.favorites = favorites
}

const addFavorite = (state, { id: listingId }) => {
  state.favorites = { ...state.favorites, [listingId]: true }
}

const removeFavorite = (state, { id: listingId }) => {
  state.favorites[listingId] = false
}

const setIsLoadingFavorite = (state, isLoadingFavorite) => {
  Object.assign(state, { isLoadingFavorite })
}

export default {
  setFavorites,
  addFavorite,
  removeFavorite,
  setIsLoadingFavorite,
}
