import Vue from 'vue'

const blogLinkClickedEvent = ({ subject }) => ({
  event: 'blogLinkClicked',
  blog: { subject },
  _clear: true,
})

export default {
  postClicked ({ subject }) {
    const event = blogLinkClickedEvent({ subject })
    Vue.$clickstream.trackEvent(event)
  },
}
