import { getUserFavorites, addUserFavorite, removeUserFavorite } from '@/api/favorite'
import { getAnonymousId } from '@/store/auth/selector'
import storage from '@/storage/local-storage'

const getFavorites = async (store) => {
  const { rootState, rootGetters, commit } = store

  const { token } = rootState.auth

  if (!token) return

  const { glueEndpoint } = rootGetters
  const { domain } = rootState.context
  const xDeviceId = getAnonymousId(rootState)

  const { data } = await getUserFavorites(glueEndpoint, {
    token, xDeviceId, domain, images: 'webp',
  })
  commit('setFavorites', data.items)
}

const addFavorite = async ({
  rootState, rootGetters, commit,
}, { id, isDevelopment }) => {
  const { token } = rootState.auth
  const xDeviceId = getAnonymousId(rootState)
  const { userInterestEndpoint } = rootGetters

  const { data } = await addUserFavorite(userInterestEndpoint, {
    id, isDevelopment, token, xDeviceId,
  })
  commit('addFavorite', data.listing)
}

const removeFavorite = async ({ rootState, rootGetters, commit }, { id, isDevelopment }) => {
  const { token } = rootState.auth
  const xDeviceId = getAnonymousId(rootState)
  const { userInterestEndpoint } = rootGetters
  await removeUserFavorite(userInterestEndpoint, {
    id, isDevelopment, token, xDeviceId,
  })
  commit('removeFavorite', { id })
}

function checkFavoriteStorage () {
  const { id, isDevelopment } = storage.getFavoriteState()
  const params = { id, isDevelopment }

  if (id) {
    this
      .dispatch('favorites/addFavorite', params)
      .finally(() => storage.cleanFavoriteState())
  }
}

export default {
  getFavorites,
  addFavorite,
  removeFavorite,
  checkFavoriteStorage,
}
