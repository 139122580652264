import favoritesStore from '@/store/favorites'
import recommendationsStore from '@/store/recommendations'

export default {
  created () {
    this.$_mixin_registerStoreModuleBase()
  },

  methods: {
    $_mixin_registerStoreModuleBase () {
      this.$_mixin_registerStoreModule('favorites', favoritesStore)
      this.$_mixin_registerStoreModule('recommendations', recommendationsStore)
    },

    $_mixin_registerStoreModule (moduleName, moduleObj, options) {
      if (!this.$store.hasModule(moduleName)) {
        this.$store.registerModule(moduleName, moduleObj, options)
      }
    },
  },
}
