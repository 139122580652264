import { mapGetters } from 'vuex'
import { icHeart, icHeartFilled } from '@vivareal/lina-icons'
import auth from '@/utils/auth'
import localStorage from '@/storage/local-storage'

import tracker from './oz-favorite.tracker'

export default {
  name: 'oz-favorite',

  props: {
    hasText: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
    listing: { type: Object, required: true },
    subject: { type: String, required: true },
    appearance: { type: String, default: 'link' },
  },

  data () {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged',
      favorites: 'favorites/favorites',
    }),

    notLogged () {
      return !this.isLogged
    },

    props () {
      const { color, hasText } = this
      const icon = this.favorited ? icHeartFilled : icHeart

      return {
        class: [
          'favorite-button',
          this.favorited ? 'favorited' : '',
          hasText ? 'favorite-button__container' : '',
          this.showAsButton ? 'favorite-button__text' : '',
          this.showAsIcon ? 'favorite-button__icon' : '',
        ],
        props: { icon, color },
      }
    },

    buttonText () {
      return this.favorited ? 'Remover favorito' : 'Favoritar'
    },

    favorited () {
      return this.favorites && this.favorites[this.listing.id]
    },

    showAsIcon () {
      return this.appearance === 'icon'
    },

    showAsButton () {
      return this.appearance === 'button'
    },
  },

  methods: {
    toggle (e) {
      e.preventDefault()
      e.stopPropagation()

      if (this.notLogged) {
        this.track('LOGIN')

        const { id, isDevelopment } = this.listing
        const { origin } = window.location
        const { loginUrl, currentFullPath } = this.$store.getters
        const redirectUrl = `${origin}${currentFullPath}`

        localStorage.setFavoriteState({ id, isDevelopment })

        window.location = auth.createLoginUrl(loginUrl, 'favorito', redirectUrl)
        return
      }

      this.handleFavorite()
    },

    handleFavorite () {
      const { id, isDevelopment } = this.listing
      const params = { id, isDevelopment }

      if (this.favorited) {
        this.$store.dispatch('favorites/removeFavorite', params)
        this.track('DELETE LISTING')
        return
      }

      this.$store.dispatch('favorites/addFavorite', params)
      this.track('ADD LISTING')
    },

    track (action) {
      tracker.favoriteClicked({ ...this.$props, status: !this.favorited, action })
    },
  },

  render () {
    const {
      hasText,
      toggle,
      props,
    } = this

    if (hasText) {
      return (
        <l-button {...props} onClick={toggle}>
          {this.buttonText}
        </l-button>
      )
    }

    if (this.showAsIcon) {
      return (
        <new-l-button {...props} onClick={toggle} appearance="outline" context={this.color} aria-label="Favoritar">
        </new-l-button>
      )
    }

    return (
      <span class="favorite__container">
        <l-link {...props} aria-label={this.buttonText} onClick={toggle} />
      </span>
    )
  },
}
