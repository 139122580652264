import {
  icArrowSmallDown,
  icSearch,
} from '@vivareal/lina-icons'

import newAddressTypeahead from '@/components/filters/new-address-typeahead'
import newUnitType from '@/components/filters/new-unit-type'

import { UNIT_TYPES } from '@/utils/constants'

import {
  BUSINESS_TYPES,
  LISTING_TYPES,
} from './constants'

import tracker from './new-filters.tracker'

const BUSINESS_TYPE_DEFAULT = BUSINESS_TYPES[0].value
const LISTING_TYPE_DEFAULT = LISTING_TYPES[BUSINESS_TYPE_DEFAULT]

export default {
  name: 'new-filters',

  props: {
    loading: {
      type: Boolean,
    },
    screen: {
      type: String, // for tracking purposes
    },
  },

  data () {
    return {
      filters: {
        addresses: [],
        business: BUSINESS_TYPE_DEFAULT,
        listingType: LISTING_TYPE_DEFAULT,
        unitTypes: UNIT_TYPES.UNIT_TYPE_DEFAULT,
      },
      icons: {
        icSearch,
        icArrowSmallDown,
      },
      businessValue: BUSINESS_TYPE_DEFAULT,
      businessOptions: BUSINESS_TYPES,
    }
  },

  computed: {
    glossary () {
      return this.$store.state.context.glossary
    },
  },

  watch: {
    businessValue (value) {
      this.filters.business = value === 'DEVELOPMENT' ? 'SALE' : value
    },
  },

  methods: {
    submit () {
      const {
        filters: {
          addresses,
          business,
          listingType,
        },
        labels,
      } = this

      this.tracking('SUBMIT BUTTON CLICKED', {
        filter: {
          addresses,
          businessType: business,
          unitTypes: labels,
          listingType,
        },
      })

      this.$emit('submit', this.filters)
    },

    linkClicked (link) {
      const addressFilterMock = { addresses: [] }
      this.tracking('LINK CLICKED', { link, filter: addressFilterMock })
    },

    tracking (action, { filter, link }) {
      tracker.filtersClicked({
        action,
        filter,
        link,
        screen: this.screen,
      })
    },

    updateUnitTypes ({ unitTypes, labels = [] }) {
      this.filters.unitTypes = unitTypes
      this.labels = labels
    },

    updateAddresses ({ addresses }) {
      this.filters.addresses = addresses
    },
  },

  components: {
    newUnitType,
    newAddressTypeahead,
  },
}
