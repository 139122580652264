const COLOR = 'white'

const BACKGROUND_COLORS = {
  publicationType: {
    SUPER_PREMIUM: 'highlight-1',
    PREMIUM: 'highlight-3',
  },
  constructionStatus: {
    PLAN_ONLY: 'progress-1',
    UNDER_CONSTRUCTION: 'progress-2',
    BUILT: 'progress-3',
  },
  listingStatus: {
    INACTIVE: 'highlight-1',
  },
  publisherType: {
    ABMI: 'highlight-4',
  },
}

const BACKGROUND_COLORS_DEDUPLICATION = {
  publicationType: {
    SUPER_PREMIUM: 'highlight-deduplication-1',
    PREMIUM: 'highlight-deduplication-2',
  },
}

const TEXT_COLORS = {
  publisherType: {
    ABMI: '#3C4453',
  },
}

export default COLOR
export { BACKGROUND_COLORS, BACKGROUND_COLORS_DEDUPLICATION, TEXT_COLORS }
