import { mapState } from 'vuex'
import { icVerified } from '@vivareal/lina-icons'

import localStorage from '@/storage/local-storage'
import ozCarousel from '@/components/oz-carousel'
import ozCardListingCompact from '@/components/oz-card/oz-card-listing-compact'

import tracker from './oz-recommendations-listings.tracker'

export default {
  name: 'oz-recommendations-listings',

  emits: ['recommendationsClick'],

  props: {
    showHighlight: { type: Boolean, default: false },
    recommendations: { type: Object, required: true },
    subtitle: { type: String },
    cardSize: { type: String },
    expandedCta: { type: Boolean, default: false },
    howRefPage: { type: String },
    heading: { type: String },
    // for tracking purposes
    businessTypeContext: { type: String, required: true },
    listingId: { type: String },
    screen: { type: String, default: '' },
    preTracking: { type: Boolean, default: false },
    trackOnce: { type: Boolean, default: false },
    carouselConfig: { type: Object, default: () => ({}) },
    showRecommendationCta: { type: Boolean, default: false },
  },

  data () {
    return {
      icVerified,
      tracked: this.preTracking,
      observeVisibilityConfig: {
        callback: this.visibilityChanged,
        once: this.trackOnce,
      },
      pageTracked: [],
      viewed: false,
      carousel: {},
    }
  },

  watch: {
    $route () {
      this.viewed = false
    },
  },

  computed: {
    ...mapState({
      featureToggle: (state) => state.context.featureToggle,
    }),
    listings () {
      return this.recommendations.scores
    },
  },

  methods: {
    showcaseTrack (event, extraData) {
      const {
        recommendations: data,
        listingId,
        screen,
        carousel,
      } = this

      tracker[event]({
        data,
        carousel,
        screen,
        listingId,
        ...extraData,
      })
    },

    listingClicked ({ id, position }) {
      this.showcaseTrack('showcaseClicked', {
        id, position, refPage: this.howRefPage,
      })
      localStorage.setShowcaseId(this.recommendations, this.screen)
      this.$emit('recommendationsClick')
    },

    visibilityChanged (isVisible) {
      if (!isVisible || this.viewed) return

      const { currentPage } = this.carousel
      const actions = ['VIEWED']
      if (!this.tracked) actions.push('RENDERED')

      this.pageTracked.push(currentPage)
      this.showcaseTrack('showcaseRendered', { actions })
      this.tracked = true
      this.viewed = true
    },

    onCarouselPageChange ({
      hasNext, hasPrev, currentPage, currentIndex,
    }) {
      this.carousel = {
        ...this.carousel,
        hasNext,
        hasPrev,
        currentPage,
        currentIndex,
      }

      if (!this.pageTracked.includes(currentPage)) {
        this.showcaseTrack('showcaseRendered', { actions: ['RENDERED', 'VIEWED'] })
        this.pageTracked.push(currentPage)
      }
    },

    onCarouselMounted (carousel) {
      this.carousel = carousel
    },
    reset () {
      this.$refs.carousel.reset()
      this.pageTracked = []
      this.tracked = false
      this.viewed = false
    },
    getNoFollowStatus (item) {
      if (item.listing && (item.listing.pricingInfos).length === 1) {
        const saleListing = item.listing.listingType === 'USED' && item.listing.pricingInfos[0].businessType === 'SALE'
        return this.featureToggle.saleLdpNoFollow && saleListing
      }
      return false
    },
  },

  components: {
    ozCardListingCompact,
    ozCarousel,
  },
}
