import humanize from '@/filters/humanize'

import handshake from '@/assets/images/icons/handshake.svg'

import COLOR, { BACKGROUND_COLORS, BACKGROUND_COLORS_DEDUPLICATION, TEXT_COLORS } from './constants'

const isValid = (key, value) => Object.keys(BACKGROUND_COLORS[key] || {}).includes(value)

export default {
  name: 'oz-label',

  props: {
    glossary: { type: Object, required: true },
    publicationType: { type: String },
    publisherType: { type: String },
    constructionStatus: { type: String },
    listingStatus: { type: String },
    isDeduplicationListingCard: { type: Boolean, default: false },
  },

  computed: {
    type () {
      const DEFAULT_TYPE = 'publicationType'

      if (this.listingStatus === 'INACTIVE') return 'listingStatus'
      if (Boolean(this.publisherType)) return 'publisherType'
      if (isValid(DEFAULT_TYPE, this.publicationType)) return DEFAULT_TYPE
      return 'constructionStatus'
    },

    label () {
      return this[this.type]
    },

    showDeduplicationPublicationType () {
      return this.isDeduplicationListingCard && this.type === 'publicationType'
    },

    labelProps () {
      let background = BACKGROUND_COLORS[this.type][this.label]
      if (this.showDeduplicationPublicationType) {
        background = BACKGROUND_COLORS_DEDUPLICATION[this.type][this.label]
      }

      const textColor = TEXT_COLORS?.[this.type]?.[this.label] || COLOR

      return {
        class: `label__container background-color-${background} ${this.showDeduplicationPublicationType ? 'label__container--dedup' : ''}`,
        props: {
          color: textColor,
          size: 'small',
          weight: 'bolder',
        },
      }
    },

    isPublisherType () {
      return this.type === 'publisherType'
    },

    publisherTypeIcon () {
      return this.isPublisherType && (
        <new-l-tooltip class="label__container--tooltip" position="bottom" arrow-position="left">
          <div class="label__container--tooltip-icon" slot="icon">
            <img src={handshake} class="label__container--icon" />
            <span>Associado ABMI</span>
          </div>
          Essa imobiliária faz parte do grupo ABMI
          (Associação Brasileira do Mercado Imobiliário), reconhecida pelo Grupo OLX
        </new-l-tooltip>
      )
    },
  },

  render () {
    const {
      glossary,
      type: key,
      label: value,
      labelProps,
      publisherTypeIcon,
    } = this

    return isValid(key, value) && (
      <l-text {...labelProps}>
        {publisherTypeIcon || humanize({ glossary, key, value })}
      </l-text>
    )
  },
}
