/* eslint-disable max-len */
import isNil from 'lodash/isNil'
import transform from 'lodash/transform'
import {
  AMENITIES,
  RESULTS,
  UNIT_TYPES,
  RENTAL_TYPES,
} from '@/utils/constants'

import { unitTypeFieldsParam } from '@/utils/transformers/unit-type'
import { UNIT_TYPES_URL } from '../transformers/unit-type/enum-urls'

const translater = {
  bedrooms: 'quartos',
  bathrooms: 'banheiros',
  parkingSpaces: 'vagas',
  priceMin: 'precoMinimo',
  priceMax: 'precoMaximo',
  priceMinCondo: 'precoMinimoCondo',
  priceMaxCondo: 'precoMaximoCondo',
  rentalPeriod: 'tipoAluguel',
  rentalTotalPriceMin: 'precoTotalMinimo',
  rentalTotalPriceMax: 'precoTotalMaximo',
  usableAreasMin: 'areaMinima',
  usableAreasMax: 'areaMaxima',
  totalAreasMin: 'areaTotalMinima',
  totalAreasMax: 'areaTotalMaxima',
  externalId: 'id',
  displayAddressType: 'endereco',
  page: 'pagina',
  business: 'transacao',
  listingType: 'tipo',
  sort: 'ordem',
  unitTypes: 'tipos',
  addresses: 'onde',
  hasVirtualTour: 'visitaVirtual',
  gclid: 'gclid',
  amenities: 'amenities',
  warranties: 'warranties',
  stamps: 'stamps',
  advertiserName: 'imobiliaria',
  transportNearBy: 'proximoAoMetro',
  q: 'q',
}

// Format address to be used as values in querystring
const encodeAddress = (address) => {
  const filteredList = Object.keys(address)
    .filter((field) => RESULTS.ALLOWED_ADDRESS_FIELDS.includes(field))

  return RESULTS.ALLOWED_ADDRESS_FIELDS.map((key) => {
    const foundField = filteredList.find((field) => field === key)
    return address[foundField]
  }).join(',')
}

const filterFirstResult = (address) => address.locationId !== 'BR'

const transformer = {
  bedrooms: {
    toParam: (value) => (value || undefined),
  },

  bathrooms: {
    toParam: (value) => (value || undefined),
  },

  parkingSpaces: {
    toParam: (value) => (value || undefined),
  },

  priceMin: {
    toParam: (value) => (value || undefined),
  },

  priceMax: {
    toParam: (value) => (value || undefined),
  },

  priceMinCondo: {
    toParam: (value) => (value || undefined),
  },

  priceMaxCondo: {
    toParam: (value) => (value || undefined),
  },

  rentalTotalPriceMin: {
    toParam: (value) => (value || undefined),
  },

  rentalTotalPriceMax: {
    toParam: (value) => (value || undefined),
  },

  usableAreasMin: {
    toParam: (value) => (value || undefined),
  },

  usableAreasMax: {
    toParam: (value) => (value || undefined),
  },

  totalAreasMin: {
    toParam: (value) => (value || undefined),
  },

  totalAreasMax: {
    toParam: (value) => (value || undefined),
  },

  externalId: {
    toParam: (value) => (value || undefined),
  },

  displayAddressType: {
    toQuery: () => ('completo'),
    toParam: (value) => (value ? 'STREET' : undefined),
  },

  hasVirtualTour: {
    toParam: (value) => (value ? value === 'true' : false),
  },

  page: {
    toParam: (value) => (value || undefined),
  },

  business: {
    toQuery: (value, glossary) => (glossary.items.businessType.items[value].singular || undefined),
    toParam: (value, glossary) => {
      const { items } = glossary.items.businessType
      const result = Object.keys(items)
        .find((key) => value === items[key].singular)
      return (result || undefined)
    },
  },

  listingType: {
    toQuery: (value, glossary) => (glossary.items.listingType.items[value].singular || undefined),
    toParam: (value, glossary) => {
      const { items } = glossary.items.listingType
      const result = Object.keys(items)
        .find((key) => value === items[key].singular)

      return (result || undefined)
    },
  },

  sort: {
    toQuery: (value) => {
      const option = RESULTS.SORT_OPTIONS.find((item) => item.value && item.value === value)
      return (option && option.label) || undefined
    },
    toParam: (value) => {
      const option = RESULTS.SORT_OPTIONS.find((item) => item.label && item.label === value)
      return (option && option.value) || undefined
    },
  },

  rentalPeriod: {
    toQuery: (value) => {
      const values = value.map((i) => RENTAL_TYPES.find((t) => t.value === i)?.text)
      return values.filter(Boolean).join(',') || undefined
    },
    toParam: (value) => {
      const values = value.split(',').map((i) => RENTAL_TYPES.find((t) => t.text === i)?.value)
      return values.filter(Boolean)
    },
  },

  gclid: {
    toQuery: (value) => (value || undefined),
    toParam: (value) => (value || undefined),
  },

  unitTypes: {
    toQuery: (value, _glossary, _unitTypeGlossary) => {
      if (value === UNIT_TYPES.UNIT_TYPE_DEFAULT) return undefined

      const unitsForUrlList = []
      const unitTypesV3List = value.unitTypesV3.split(',')

      unitTypesV3List.forEach((el) => unitsForUrlList.push(UNIT_TYPES_URL[el]))

      return unitsForUrlList.toString()
    },
    toParam: (value, _glossary, unitTypeGlossary) => {
      const unitTypes = value
        ? unitTypeFieldsParam(value, unitTypeGlossary)
        : undefined

      return unitTypes
    },
  },

  addresses: {
    toQuery: (addresses) => {
      const addressesList = Array.isArray(addresses) ? addresses : [addresses]
      return (addressesList.filter(filterFirstResult).map(encodeAddress).join(';') || undefined)
    },
    toParam: (value) => {
      if (value) {
        const addressesList = decodeURI(value).split(';')
        return addressesList.map((field) => field.split(','))
          .map((item) => Object.assign({}, ...item.map((val, index) => ({
            [RESULTS.ALLOWED_ADDRESS_FIELDS[index]]: val,
          }))))
      }

      return (value || undefined)
    },
  },

  amenities: {
    toQuery: (amenities) => {
      const amenitiesTexts = AMENITIES.reduce((acc, curVal) => {
        if (amenities?.includes(curVal.id)) acc.push(curVal.text)
        return acc
      }, [])

      return amenitiesTexts.join(',') || undefined
    },
    toParam: (amenities) => AMENITIES.reduce((acc, curVal) => {
      if (amenities?.split(',').includes(curVal.text)) acc.push(curVal.id)
      return acc
    }, []),
  },

  advertiserName: {
    toQuery: (value) => value || undefined,
    toParam: () => (undefined),
  },

  warranties: {
    toParam: (value) => (value || undefined),
  },

  transportNearBy: {
    toParam: (value) => (value || undefined),
  },

  stamps: {
    toQuery: (value) => {
      const stampsText = RESULTS.STAMPS.reduce((acc, curVal) => {
        if (value?.includes(curVal.id)) acc.push(curVal.text)
        return acc
      }, [])

      return stampsText.join(',') || undefined
    },
    toParam: (value) => RESULTS.STAMPS.reduce((acc, curVal) => {
      if (value?.split(',').includes(curVal.text)) acc.push(curVal.id)
      return acc
    }, []),
  },

  q: {
    toQuery: (value) => (value || undefined),
    toParam: (value) => (value || undefined),
  },
}

// ex: bedrooms => quartos
const getQueryByParams = (
  params,
  glossary,
  unitTypeGlossary,
) => transform(params, (obj, value, param) => {
  const query = translater[param]
  if (query && value && !isNil(value)) {
    const { toQuery } = transformer[param]

    obj[query] = toQuery ? toQuery(value, glossary, unitTypeGlossary, params) : value
  }
})

// ex: quartos => bedrooms
const getParamsByQuery = (
  query,
  glossary,
  unitTypeGlossary,
) => transform(translater, (obj, value, filter) => {
  if (query && value && !isNil(query[value])) {
    const { toParam } = transformer[filter]
    obj[filter] = toParam(query[value], glossary, unitTypeGlossary, query)
  }
})

export {
  getQueryByParams, getParamsByQuery, translater,
}
