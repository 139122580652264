import Vue from 'vue'

const buildInternalLinkEvent = (widget, subject) => ({
  internalLink: { subject, widget },
  event: 'internalLinkClicked',
  _clear: true,
})

export default {
  linkClicked (category, text) {
    const event = buildInternalLinkEvent(category, text)
    Vue.$clickstream.trackEvent(event)
  },

  expandLinksClicked () {
    Vue.$clickstream.trackEvent({
      event: 'expandLinksClicked',
    })
  },
}

export { buildInternalLinkEvent }
