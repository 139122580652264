import locationsAPI from '@/api/new-locations'

import {
  getLocations,
  getLocationsGroup,
  getSuggestionsValues,
} from '@/utils/transformers/locations-suggestions'

const FIELDS_TEXT = {
  city: 'Cidades',
  neighborhood: 'Bairros',
  street: 'Ruas',
  account: 'Imobiliárias',
  condominium: 'Condomínios',
}

const ALLOWED_FIELDS = {
  home: [
    'city',
    'neighborhood',
    'street',
    'account',
    'condominium',
  ],
  results: [
    'city',
    'neighborhood',
    'street',
  ],
}
const autocompleteMixin = {
  data () {
    return {
      locations: [],
      savedLocations: [],
      hasLocations: false,
      timeout: null,
    }
  },

  mounted () {
    const { addresses = [] } = this.filters

    if (addresses.length) {
      const formatedAddresses = addresses.map((i) => {
        i.type = i.type.split(',')[0].toLowerCase()
        return { address: i }
      })

      const locations = getLocationsGroup(formatedAddresses, FIELDS_TEXT)
      this.hasLocations = true
      this.checkedLocations = locations.reduce((a, c) => a.concat(c.items), []).map((i) => i.value)
    }
  },

  methods: {
    $_autocomplete_input () {
      clearTimeout(this.timeout)

      this.$_autocomplete_search_locations()
    },

    $_autocomplete_emit (locations) {
      if (!this.hasLocations) {
        this.$emit('selected:locations', { addresses: locations })
      }

      this.$_autocomplete_save_locations()
    },

    $_autocomplete_save_locations () {
      const locations = []

      this.checkedLocations.forEach((value) => {
        const address = JSON.parse(value)
        locations.push(getSuggestionsValues(address.type, [{ address }])[0])
      })

      this.savedLocations = locations
      this.hasLocations = false
    },

    $_autocomplete_search_locations () {
      if (this.query.length < this.minQuery) {
        this.locations = []
        return
      }

      this.timeout = setTimeout(() => {
        const { business } = this.filters
        const filters = { ...this.filters }

        locationsAPI.get(this.$store, filters, this.query, business).then((response) => {
          const locations = getLocations(response.data, ALLOWED_FIELDS[this.context])
          this.locations = getLocationsGroup(locations, FIELDS_TEXT)
        })
      }, this.delay)
    },
  },
}

export default autocompleteMixin
