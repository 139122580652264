const IMAGE_SMALL_SIZES = {
  desktop: {
    width: 176,
    height: 130,
  },
  mobile: {
    width: 328,
    height: 184,
  },
  tablet: {
    width: 202,
    height: 130,
  },
}

const IMAGE_REGULAR_SIZE = {
  desktop: {
    width: 232,
    height: 130,
  },
  mobile: {
    width: 328,
    height: 184,
  },
  tablet: {
    width: 232,
    height: 130,
  },
}

const IMAGE_LARGE_SIZE = {
  desktop: {
    width: 264,
    height: 200,
  },
  mobile: {
    width: 328,
    height: 184,
  },
  tablet: {
    width: 308,
    height: 184,
  },
}

const CONTENT_SMALL_GUTTER = {
  DEFAULT: 'normal',
}

const CONTENT_REGULAR_GUTTER = {
  DEFAULT: {
    top: 'onehalf',
    left: 'onehalf',
    right: 'onehalf',
    bottom: 'normal',
  },
}

const CONTENT_LARGE_GUTTER = {
  DEFAULT: 'onehalf',
}

const CONTENT_GUTTERS_MAPPER = Object.freeze({
  SMALL: CONTENT_SMALL_GUTTER,
  REGULAR: CONTENT_REGULAR_GUTTER,
  LARGE: CONTENT_LARGE_GUTTER,
})

const IMAGE_SIZES_MAPPER = Object.freeze({
  SMALL: IMAGE_SMALL_SIZES,
  REGULAR: IMAGE_REGULAR_SIZE,
  LARGE: IMAGE_LARGE_SIZE,
})

const ITL_PARAMS = {
  similarity: {
    itl_id: '1000137',
  },
  cooccurrence: {
    itl_id: '1000138',
  },
  factorization: {
    itl_id: '1000139',
  },
  similarity_development: {
    itl_id: '1000140',
  },
  similarity_advertiser: {
    itl_id: '1000141',
  },
  similarity_mail: {
    itl_id: '1000142',
  },
  history: {
    itl_id: '1000143',
  },
  simple_user_development: {
    itl_id: '1000144',
  },
  popularity_apartment: {
    itl_id: '1000145',
  },
  popularity_home: {
    itl_id: '1000146',
  },
  nearby_developments: {
    itl_id: '1000147',
  },
  nearby_recent_listings: {
    itl_id: '1000148',
  },
  user_recent_listings: {
    itl_id: '1000149',
  },
  ranking: {
    itl_id: '1000150',
  },
  markdown: {
    itl_id: '1000151',
  },
  nearby_markdown: {
    itl_id: '1000152',
  },
  user_markdown: {
    itl_id: '1000153',
  },
  nearby_pois: {
    itl_id: '1000154',
  },
  simple_user_profile: {
    itl_id: '1000155',
  },
  simple_user_favorite: {
    itl_id: '1000156',
  },
  nearby_owner_listings: {
    itl_id: '1000157',
  },
  user_profile_owner_listings: {
    itl_id: '1000158',
  },
  sponsor_advertiser: {
    itl_id: '1000159',
  },
  undefined: {
    itl_id: '1000160',
  },
}

export {
  CONTENT_GUTTERS_MAPPER,
  IMAGE_SIZES_MAPPER,
  ITL_PARAMS,
}
