const calcPriceValue = (priceMin, priceMax) => {
  const priceMinNumber = Number(priceMin || 0)
  const priceMaxNumber = Number(priceMax || 0)

  const mountObjReturn = (value) => (
    { 'profile.pricingInfo.price': value }
  )

  if (priceMinNumber && priceMaxNumber) {
    return mountObjReturn((priceMinNumber + priceMaxNumber) / 2)
  }

  if (priceMinNumber) return mountObjReturn(priceMinNumber)

  return mountObjReturn(priceMaxNumber)
}

const buildUsableAreaValue = (usableAreasMin, usableAreasMax) => {
  const mountObjReturn = (value) => (
    { 'profile.area': value }
  )
  if (usableAreasMin && usableAreasMax) return mountObjReturn(usableAreasMin)
  if (usableAreasMin) return mountObjReturn(usableAreasMin)

  return mountObjReturn(usableAreasMax)
}

const transformer = {
  bedrooms: {
    transform: ({ bedrooms }) => ({ 'profile.bedrooms': bedrooms }),
  },
  bathrooms: {
    transform: ({ bathrooms }) => ({ 'profile.bathrooms': bathrooms }),
  },
  parkingSpaces: {
    transform: ({ parkingSpaces }) => ({ 'profile.parkingSpaces': parkingSpaces }),
  },
  usableAreasMin: {
    transform: ({ usableAreasMin }) => (buildUsableAreaValue(usableAreasMin)),
  },
  usableAreasMax: {
    transform: ({ usableAreasMax }) => (buildUsableAreaValue(usableAreasMax)),
  },
  business: {
    transform: ({ business }) => ({ 'profile.pricingInfo.businessType': business }),
  },
  priceMin: {
    transform: ({ priceMin, priceMax }) => (calcPriceValue(priceMin, priceMax)),
  },
  priceMax: {
    transform: ({ priceMin, priceMax }) => (calcPriceValue(priceMin, priceMax)),
  },
  unitTypes: {
    transform: ({ unitTypes }) => ({ 'profile.unitType': unitTypes.unitTypes }),
  },
  addresses: {
    transform: ({ addresses = [] }) => {
      const [address] = [].concat(addresses)

      return {
        'profile.address.city': address?.city,
        'profile.address.latitude': address?.pointLat,
        'profile.address.longitude': address?.pointLon,
        'profile.address.neighborhood': address?.neighborhood,
        'profile.address.state': address?.state,
      }
    },
  },
  amenities: {
    transform: ({ amenities = [] }) => ({ 'profile.amenities': amenities.join(',') }),
  },
  listingType: {
    transform: ({ listingType }) => ({ 'profile.type': listingType }),
  },
}

const mapper = (filters) => Object.keys(filters)
  .map((key) => transformer[key]?.transform(filters))
  .reduce((obj, filter) => ({ ...obj, ...filter }), {})

export default mapper
