export default {
  name: 'oz-loading',

  props: {
    full: { type: Boolean },
    white: { type: Boolean },
    purple: { type: Boolean },
  },

  computed: {
    customClass () {
      return {
        'loading__container--full': this.full,
        'loading__container--white': this.white,
        'loading__container--purple': this.purple,
      }
    },
  },
}
