import Vue from 'vue'

const event = 'filtersClicked'

const filtersClicked = ({
  action,
  screen,
  filter = {},
  link = {},
}) => {
  const data = {
    filters: {
      action, // SUBMIT BUTTON CLICKED | LINK CLICKED
      screen, // HOME HERO | HOME HERO LEFT | HOME HERO RIGHT | HOME HERO BOTTOM
      filter,
      link,
    },
    event,
  }

  Vue.$clickstream.trackEvent(data)
}

export default {
  filtersClicked,
}
