import { mapGetters, mapState } from 'vuex'

import ozRecommendationsListings from '@/components/oz-recommendations-listings'
import recommendationsTracker from '@/components/oz-recommendations-listings/oz-recommendations-listings.tracker'
import defaultSettings from './oz-recommendations.default-settings'

export default {
  name: 'oz-recommendations',

  props: {
    recommendations: { type: Array, required: true },
    expandedCta: { type: Boolean, default: false },
    // for tracking purposes
    businessTypeContext: { type: String, default: '' },
    listingId: { type: String },
    settings: { type: Object, default: () => defaultSettings },
    screen: { type: String, default: '' },
    trackOnMounted: { type: Boolean, default: true },
    currentPlatform: { type: String },
    howRefPage: { type: String },

  },

  computed: {
    ...mapState({
      featureToggle: (state) => state.context.featureToggle,
    }),

    ...mapGetters({
      hasOfficialStoreData: 'officialStore/hasOfficialStore',
    }),

    hasOfficialStore () {
      return this.featureToggle.official_store && this.hasOfficialStoreData
    },

    recommendationList () {
      const { isLargeInstances } = this.settings
      return this.recommendations.map((item, index) => {
        const isLarge = isLargeInstances.includes(index)
        const settingsFrom = isLarge ? 'isLarge' : 'default'
        const { carouselConfig } = this.settings[settingsFrom]
        const headingSize = Math.ceil((5 + index) / 2)

        return {
          config: {
            cardSize: isLarge ? 'large' : 'regular',
            carouselConfig,
          },
          recommendations: item,
          heading: headingSize > 6 ? 'h6' : `h${headingSize}`,
          showHighlight: this.hasOfficialStore && item.recommender === 'similarity_development',
        }
      })
    },
  },

  watch: {
    recommendations () {
      if (!__BROWSER__) return
      // delay for ldp navigation
      setTimeout(() => {
        this.trackRecommendations()
      }, 500)
    },
  },

  mounted () {
    this.$nextTick(() => {
      if (this.trackOnMounted) this.trackRecommendations()
    })
  },

  methods: {
    reset () {
      this.$refs.recommendationsListings?.forEach((recommendation) => {
        recommendation.reset()
      })
    },

    trackRecommendations () {
      const {
        recommendations,
        settings,
        screen,
        currentPlatform,
      } = this

      recommendationsTracker.recommendationsPreTracking({
        recommendations: [...recommendations],
        settings,
        screen,
        currentPlatform,
      })
    },
  },

  components: {
    ozRecommendationsListings,
  },
}
