import Vue from 'vue'

const buildNeighborhoodsGuideLinkClickedEvent = (linkLabel, position) => ({
  neighborhoodsGuideLink: {
    linkLabel, position,
  },
  event: 'neighborhoodsGuideInternalLinkClicked',
  _clear: true,
})

export default {
  linkClicked (linkLabel, position) {
    const event = buildNeighborhoodsGuideLinkClickedEvent(linkLabel, position)
    Vue.$clickstream.trackEvent(event)
  },
}

export { buildNeighborhoodsGuideLinkClickedEvent }
