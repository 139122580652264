import { marked } from 'marked'
import { mapGetters } from 'vuex'
import tracker from './new-advertise-property.tracker'

export default {
  name: 'new-advertise-property',

  props: {
    advertiseContent: { type: Object },
  },

  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),

    textColor () {
      return this.advertiseContent.textColor || ''
    },

    linkReference () {
      if (this.isMobile) {
        return this.advertiseContent.linkMobile
      }

      return this.advertiseContent.linkDesktop
    },
  },

  methods: {
    track () {
      tracker.ownerLinkClicked({
        subject: this.advertiseContent.subject || 'Subject is not defined',
      })
    },

    convertMarkDown (markdownText) {
      return marked(this.insertLineBreak(markdownText))
    },

    insertLineBreak (markdownText) {
      return markdownText.replace(/\n/g, '<br>')
    },
  },

}
