import { render, staticRenderFns } from "./oz-zap-magazine-post-list.html?vue&type=template&id=14a3a193&"
import script from "./oz-zap-magazine-post-list.js?vue&type=script&lang=js&"
export * from "./oz-zap-magazine-post-list.js?vue&type=script&lang=js&"
import style0 from "./oz-zap-magazine-post-list.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports