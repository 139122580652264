export const ITL_PARAMS = {
  popularity: {
    itl_id: '1000078',
  },
  see_also: {
    itl_id: '1000079',
  },
  other_properties: {
    itl_id: '1000080',
  },
  nearby: {
    itl_id: '1000081',
  },
  poi_nearby: {
    itl_id: '1000082',
  },
  advertisers_available: {
    itl_id: '1000083',
  },
  undefined: {
    itl_id: '1000102',
  },
}
