import KeenSlider from 'keen-slider'

import {
  icArrowBigShadowLeft,
  icArrowBigShadowRight,
  icArrowSmallLeft,
  icArrowSmallRight,
} from '@vivareal/lina-icons'

const CONTROLS_CONFIG = {
  over: {
    cssClass: 'oz-carousel__button-over',
    component: 'l-link',
    name: 'over',
    icons: {
      prev: icArrowBigShadowLeft,
      next: icArrowBigShadowRight,
    },
    props: {
      color: 'inverse',
    },
  },
  under: {
    cssClass: 'oz-carousel__button-under',
    component: 'l-button',
    icons: {
      prev: icArrowSmallLeft,
      next: icArrowSmallRight,
    },
    props: {
      appearance: 'outline',
      size: 'small',
    },
    name: 'under',
  },
}

export default {
  name: 'oz-carousel',

  props: {
    slidesPerView: {
      type: Number,
      default: 1,
    },
    spacing: {
      type: [Number, String],
      default: 0,
    },
    breakpoints: {
      type: Object,
      default: null,
    },
    slideClass: {
      type: String,
      required: true,
    },
    controlsPosition: {
      type: String,
      default: 'over',
      validator: (value) => !!CONTROLS_CONFIG[value],
    },
    hasControls: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      slider: null,
      numberOfPages: 0,
      ready: false,
      hasPrev: false,
      hasNext: false,
    }
  },

  computed: {
    controlsConfig () {
      return CONTROLS_CONFIG[this.controlsPosition] ?? {}
    },

    config () {
      const { controlsConfig } = this

      return {
        cssClasses: [
          'oz-carousel',
          `oz-carousel--controls-${controlsConfig.name}`,
        ],
      }
    },
  },

  mounted () {
    this.slider = new KeenSlider(this.$refs.slider, {
      slideChanged: (slider) => {
        if (this.ready) {
          this.emitEvent('carousel:page-change', slider)
        }
      },
      mounted: (slider) => {
        const { slidesPerView, size } = slider.details()
        this.numberOfPages = slidesPerView === 1 ? size - 1 : Math.ceil(size / slidesPerView)
        this.emitEvent('carousel:mounted', slider)
        this.ready = true
      },
      loop: this.loop,
      slidesPerView: this.slidesPerView,
      spacing: this.spacing,
      breakpoints: this.breakpoints,
      slides: `.${this.slideClass}`,
    })
  },

  beforeDestroy () {
    this.slider?.destroy()
  },

  methods: {
    emitEvent (event, slider) {
      const { slidesPerView, relativeSlide, size } = slider.details()
      const { numberOfPages } = this

      const currentPage = Math.ceil(relativeSlide / slidesPerView)
      const hasPrev = relativeSlide > 0
      const hasNext = (relativeSlide + slidesPerView) < size

      this.hasPrev = hasPrev
      this.hasNext = hasNext

      this.$emit(event, {
        currentIndex: relativeSlide,
        currentPage: (currentPage + 1),
        hasPrev,
        hasNext,
        numberOfPages,
        itemsPerPage: slidesPerView,
        itemsCount: size,
      })
    },

    next (ev) {
      ev.preventDefault()
      ev.stopPropagation()
      const { slidesPerView, relativeSlide, size } = this.slider.details()
      this.slider.moveToSlideRelative((relativeSlide + slidesPerView) % size)
    },

    prev (ev) {
      ev.preventDefault()
      ev.stopPropagation()
      const { slidesPerView, relativeSlide, size } = this.slider.details()
      this.slider.moveToSlideRelative((size + relativeSlide - slidesPerView) % size)
    },

    reset () {
      this.slider?.refresh()
    },
  },

}
