import tracker from './oz-zap-magazine-post-list.tracker'
import { FALLBACK_DATA } from '@/utils/constants'

export default {
  props: {
    posts: { type: Array, default: () => [] },
    loading: { type: Boolean, default: true },
    showPosts: { type: Number, default: 3 },
    empty: { type: Boolean },
    blogContent: { type: Object, default: () => FALLBACK_DATA.FALLBACK_HOME_PAGE_DATA.blog },
  },

  computed: {
    buttonText () {
      return !(this.loading || this.posts.length) ? 'Acesse' : 'Leia mais dicas'
    },
    totalPosts () {
      return this.loading ? this.showPosts : Math.min(this.posts.length, this.showPosts)
    },
  },

  methods: {
    linkClicked (e) {
      tracker.postClicked({ subject: e.target.innerText })
    },
  },
}
