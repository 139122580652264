var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main__container"},[_c('new-hero',{attrs:{"hero":_vm.cmsContent.header}},[_c('new-filters',{attrs:{"screen":"HOME HERO RIGHT","loading":_vm.loading},on:{"submit":_vm.search}})],1),_c('oz-interests-showcase',{attrs:{"interests":_vm.cmsContent.showcase}}),_c('new-advertise-property',{attrs:{"advertiseContent":_vm.cmsContent.banner}}),_c('oz-ads',{attrs:{"track-id":"homeAdSpecial","gutter":{
    top: { xs: 'normal', sm: 'triple' },
    bottom: { xs: 'doublehalf', sm: 'triple' },
  }}}),_c('l-box',{attrs:{"gutter":{
    top: { sm: 'onehalf' },
  }}},[_c('oz-user-recommendations',{ref:"userRecomendations",attrs:{"source":"HOME","screen":"HOME","slots":20,"quantity":12,"settings":_vm.recommendationsSettings}})],1),_c('oz-ads',{attrs:{"track-id":"homeAdInBetweenHome","gutter":{
    top: { xs: 'normal', sm: 'triple' },
    bottom: { xs: 'doublehalf', sm: 'triple' },
  }}}),_c('oz-zap-magazine',{attrs:{"blog-content":_vm.cmsContent.blog}}),_c('oz-main-locations'),_c('oz-seo-widgets-links',{attrs:{"widgets":_vm.widgets}}),_c('oz-seo-widgets-neighborhoods',{attrs:{"neighborhoods-states":_vm.neighborhoodsStates}}),_c('oz-ads',{attrs:{"track-id":"homeAdBottom","gutter":{
    top: { xs: 'quarter', sm: 'double' },
    bottom: { xs: 'onequarter', sm: 'triple' },
  }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }