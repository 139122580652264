/* eslint quote-props: ["error", "consistent"] */
const states = {
  'Acre': 'AC',
  'Alagoas': 'AL',
  'Amapá': 'AP',
  'Amazonas': 'AM',
  'Bahia': 'BA',
  'Ceará': 'CE',
  'Distrito Federal': 'DF',
  'Espírito Santo': 'ES',
  'Goiás': 'GO',
  'Maranhão': 'MA',
  'Mato Grosso': 'MT',
  'Mato Grosso do Sul': 'MS',
  'Minas Gerais': 'MG',
  'Pará': 'PA',
  'Paraíba': 'PB',
  'Paraná': 'PR',
  'Pernambuco': 'PE',
  'Piauí': 'PI',
  'Rio de Janeiro': 'RJ',
  'Rio Grande do Norte': 'RN',
  'Rio Grande do Sul': 'RS',
  'Rondônia': 'RO',
  'Roraima': 'RR',
  'Santa Catarina': 'SC',
  'São Paulo': 'SP',
  'Sergipe': 'SE',
  'Tocantins': 'TO',
}

const getSuggestionsAddresses = (field = '', address = {}, advertiser = {}, condominium = {}) => {
  if (!field) return ''

  const {
    street,
    neighborhood,
    city,
    state,
  } = address

  const fields = {
    street: `${street}, ${city} - ${states[state]}`,
    neighborhood: `${neighborhood}, ${city} - ${states[state]}`,
    city: `${city} - ${states[state]}`,
    account: advertiser.name,
    condominium: condominium.name,
  }

  return fields[field]
}

const getSuggestionsValues = (field = '', items = []) => {
  if (!field) return []

  return items.map(({
    address,
    advertiser,
    condominium,
    link = '',
  }) => {
    const treatedPoints = () => {
      const returnPoint = (pointLat, pointLon) => ({ pointLat, pointLon })

      if (address.pointLat || address.pointLon) {
        const { pointLat = '', pointLon = '' } = address
        return returnPoint(pointLat, pointLon)
      }

      if (address.point) {
        const { lon = '', lat = '' } = address.point
        return returnPoint(lat, lon)
      }

      return {}
    }

    const {
      city = '', zone = '', street = '', locationId = '', state = '', neighborhood = '', type = '',
    } = address

    const value = JSON.stringify({
      city, zone, street, locationId, state, neighborhood, type, ...treatedPoints(),
    })

    return {
      text: getSuggestionsAddresses(field, address, advertiser, condominium),
      value,
      link,
    }
  })
}

const getLocationsGroup = (locations = [], fieldsText = {}) => {
  const fields = locations.reduce((acc, curr) => {
    acc[curr.address.type] = acc[curr.address.type] || []
    acc[curr.address.type].push(curr)
    return acc
  }, {})

  return Object.keys(fields).map((field) => ({
    group: {
      name: field,
      text: fieldsText[field] || '',
    },
    items: getSuggestionsValues(field, fields[field]),
  }))
}

const getLocations = (data = {}, allowedFields = []) => {
  const fields = allowedFields.filter((field) => (
    (data[field] && data[field].totalCount > 0)
  ))

  const locations = []

  fields.forEach((field) => {
    locations.push(...data[field].result.locations.map((item) => {
      item.address.type = field

      return {
        address: item.address,
        advertiser: item.advertiser || {},
        condominium: item.condominium || {},
        link: item.url || '',
      }
    }))
  })

  return locations
}

export {
  getLocations,
  getLocationsGroup,
  getSuggestionsValues,
  getSuggestionsAddresses,
}
