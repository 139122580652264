import { mapGetters, mapState } from 'vuex'
import formatPhone from '@/filters/format-phone'
import localStorage from '@/storage/local-storage'
import { icPhone } from '@vivareal/lina-icons'
import { LEAD_TYPE } from '@/utils/constants'
import trackerPanel from '@/utils/tracking/lead/lead-panel-clicked'
import ozLoading from '@/components/oz-loading'
import quality from '@/mixins/quality'
import auth from '@/utils/auth'

export default {
  name: 'oz-lead-phone',
  mixins: [quality],
  props: {
    text: {
      type: String,
      required: true,
    },
    listing: {
      type: Object,
      required: true,
    },
    trackingData: {
      type: Object,
      required: true,
    },
    isCTA: {
      type: Boolean,
      default: false,
    },
    showButtonIcon: {
      type: Boolean,
      default: false,
    },
    publisher: {
      type: Object,
      required: false,
    },
    showLeadFormOpen: {
      type: Boolean,
      default: false,
    },
    showPhoneDescription: {
      type: Boolean,
      default: false,
    },
    shouldOpenPhoneModal: {
      type: Boolean,
      default: false,
    },
    redirectAction: {
      type: String,
      default: 'open_phone_modal',
    },
    hideQualityStars: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      showPhone: false,
      advertiserPhones: [],
      currentListingId: null,
      icons: {
        icPhone,
      },
    }
  },

  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged',
      loading: 'leads/loading',
      leadUser: 'leads/leadUser',
      showPostLeadModal: 'ui/modals/showPostLeadModal',
      userData: 'auth/user',
    }),

    ...mapState({
      legal: (state) => state.context.legal,
    }),

    showPhoneButton () {
      return this.listing.showPhoneButton
    },

    phoneTrackingData () {
      return this.trackingData.phone
    },

    listingPosition () {
      return this.trackingData.listingPosition
    },

    isLoading () {
      const isCorrectListing = this.currentListingId === this.listing.id

      return this.loading && isCorrectListing
    },

    isNotWhatsappNumber () {
      return !this.listing.whatsappNumber
    },
    showQualityStars () {
      return this.showAnsweredRateGreaterThan80 && this.totalRatings && !this.hideQualityStars
    },

    getAdvertiserFormattedPhone () {
      this.getAdvertiserPhones()
      const formated = formatPhone(this.advertiserPhones[0])
      return `${formated.split('-')[0]}-...`
    },

    hasLeadUserData () {
      return this.leadUser.acceptTerms && this.leadUser.name && this.leadUser.email
    },

    hasAuthUserData () {
      return this.userData?.name && this.userData?.email
    },

    user () {
      return {
        id: this.leadUser?.id || this.userData?.id,
        acceptTerms: this.leadUser?.acceptTerms || this.userData?.acceptTerms,
        name: this.leadUser?.name || this.userData?.name,
        email: this.leadUser?.email || this.userData.email,
        phoneNumber: this.leadUser?.phoneNumber || this.userData?.phone,
        picture: this.userData?.picture,
        termsOptIns: true,
      }
    },
  },

  watch: {
    showPostLeadModal () {
      if (this.showPostLeadModal) {
        this.resetCurrentId()
      }
    },
    shouldOpenPhoneModal () {
      if (this.shouldOpenPhoneModal) {
        localStorage.setRedirectAction('')
        this.openPostLeadModal(this.listing.id)
      }
    },
  },

  methods: {
    resetCurrentId () {
      this.currentListingId = null
    },

    handleLogin () {
      const { origin } = window.location
      const { loginUrl, currentFullPath } = this.$store.getters
      const url = new URL(`${origin}${currentFullPath}`)

      localStorage.setRedirectAction(this.redirectAction)

      const fullLoginUrl = new URL(loginUrl)

      window.open(auth.createLoginUrl(fullLoginUrl.href, 'ver telefone', url.href), '_blank')
    },

    async sendLead () {
      this.currentListingId = this.listing.id

      this.getAdvertiserPhones()

      const { user } = this

      /** @type {import('@/store/leads/actions').LeadInput} */
      const leadData = {
        ...(user && {
          ...user,
          message: [
            'Olá! Você tem uma nova visualização de telefone.',
            `${user.name} demonstrou interesse em seu anúncio, clicando para ver seu telefone`,
            `A seguir, dados para contato com ${user.name}:`,
            `E-mail: ${user.email}`,
            `Telefone: ${user.phoneNumber || user.phone}`,
          ].join('<br>'),
          legal: this.legal,
          isLogged: this.isLogged,
          acceptTerms: true,
        }),
        listing: this.listing,
        publisher: this.publisher,
        type: LEAD_TYPE.PHONE,
        options: {
          feedback: {
            subject: this.phoneTrackingData.subject,
            screen: this.phoneTrackingData.screen,
          },
          tracking: {
            data: this.trackingData,
          },
        },
      }

      try {
        await this.$store.dispatch('leads/sendLead', leadData)
        this.$emit('onPhoneClick', this.filters)
      } catch (error) {
        const { code, location } = error
        if (location) throw error
        this.$store.dispatch('errors/setHttpStatus', code, { root: true })
      }
    },

    seePhoneClicked () {
      trackerPanel.leadPanelClicked({
        listingId: this.listing.id,
        senseOfUrgency: this.listing.listingSummary?.leads ?? {},
        subject: this.trackingData.phone.subject ?? 'LISTING DETAIL PAGE FORM',
        screen: this.trackingData.phone.screen ?? 'ONE CLICK TO LEAD BUTTON',
        action: 'CLICK',
      })

      if (!this.isLogged && !this.hasLeadUserData) {
        this.handleLogin()
        this.$store.commit('leads/setLoading', false)
      } else {
        this.openPostLeadModal(this.listing.id)
      }
    },

    getAdvertiserPhones () {
      if (!this.listing.advertiserContact) {
        this.$store.dispatch(`${this.$route.name}/advertiserPhonesFetch`, this.listing.id)
        this.advertiserPhones = []
      } else {
        this.advertiserPhones = this.listing.advertiserContact.phones
      }
    },

    openPostLeadModal () {
      this.sendLead()
    },

    clearQueryParam (param) {
      const url = new URL(window.location.href)
      url.searchParams.delete(param)
      window.history.replaceState({}, document.title, url.toString())
    },
  },

  filters: {
    formatPhone,
  },

  components: {
    ozLoading,
  },
}
