import Vue from 'vue'

const ownerLinkClicked = ({ context = 'ZAP', subject }) => {
  const data = {
    owner: {
      context,
      subject,
    },
    event: 'ownerLinkClicked',
    _clear: true,
  }

  Vue.$clickstream.trackEvent(data)
}

export default {
  ownerLinkClicked,
}
