import isEmpty from 'lodash/isEmpty'
import pluralize from '@/filters/pluralize'
import getAmenitiesParams, {
  AREAS_FIELDS,
  BASE_AMENITIES,
  getAmenitiesSchemaMarkup,
} from './constants'

export default {
  name: 'oz-base-amenities',

  props: {
    bathrooms: { type: Boolean },
    bedrooms: { type: Boolean },
    color: { type: String },
    hasIcon: { type: Boolean },
    listing: { type: Object, required: true },
    noComplement: { type: Boolean },
    parkingSpaces: { type: Boolean },
    size: {
      type: String,
      default: 'regular',
    },
    unitFloor: { type: Boolean },
    usableAreas: { type: Boolean },
    hasSeparator: { type: Boolean },
    schemaMarkup: { type: Boolean, default: false },
  },

  filters: {
    formatAmenitieValue (value, isOrdinal) {
      return isOrdinal ? `${value}º` : value
    },
  },

  computed: {
    features () {
      const rawFeatures = []
      const { isDevelopment } = this.listing

      BASE_AMENITIES.forEach((field) => {
        const amenitieParams = getAmenitiesParams(isDevelopment)[field]
        const feature = this._getFeature(amenitieParams)
        if (this[field] && feature) rawFeatures.push(feature)
      })

      return rawFeatures
    },
  },

  methods: {
    getFeatureSchemaMarkupProp (feature) {
      if (!this.schemaMarkup) return {}

      return {
        itemprop: getAmenitiesSchemaMarkup()[feature],
      }
    },

    _getComplement ({ value, singular, plural }) {
      return (plural === null)
        ? singular
        : pluralize({ value: Math.max(...value), singular, plural })
    },

    _hasComplement (feature) {
      return this.noComplement && !AREAS_FIELDS.includes(feature)
    },

    _getValue (rawValue) {
      if (!rawValue) return null

      if (Array.isArray(rawValue)) {
        const { length } = rawValue

        if (!length) return 0
        if (length > 1) return `${Math.min(...rawValue)} - ${Math.max(...rawValue)}`
        return Math.min(...rawValue)
      }

      return rawValue
    },

    _getFeatureExtra (value) {
      const {
        feature,
        plural = null,
        singular,
      } = value || {}

      if (isEmpty(value)) return null

      const extraRawValue = this.listing[feature]
      const extraValue = this._getValue(extraRawValue)
      const extraComplement = this._getComplement({ value: extraRawValue, singular, plural })

      return {
        value: extraValue,
        complement: extraComplement,
        short: (extraRawValue.length > 1),
      }
    },

    _getFeature ({
      feature,
      fallback,
      icon,
      plural = null,
      singular,
      className,
      extra,
      isOrdinal,
    }) {
      let rawValue = this.listing[feature]
      let value = this._getValue(rawValue)

      if (!value && fallback) {
        rawValue = this.listing[fallback]
        value = this._getValue(rawValue)
      }

      if (!value) {
        return null
      }

      let complement = this._getComplement({ value: rawValue, singular, plural })
      if (this._hasComplement(feature)) {
        complement = ''
      }

      const values = {
        feature,
        value,
        icon,
        complement,
        className,
        isOrdinal,
      }

      const extraValues = this._getFeatureExtra(extra)

      if (extraValues && extraValues.value) {
        return {
          ...values,
          extra: extraValues,
        }
      }

      return values
    },
  },
}
