import Vue from 'vue'

import recommendationsDefaultSettings from '@/components/oz-recommendations/oz-recommendations.default-settings'
import localStorage from '@/storage/local-storage'
import trackingPage from '@/utils/constants/tracking/tracking-page'

const buildShowcaseRenderedEvent = ({
  actions,
  showcase,
  listingIds,
  pagination,
}) => ({
  showcase: {
    actions: actions || ['RENDERED'],
    showcase,
    listingIds,
    pagination,
  },
  event: 'showcaseRendered',
  _clear: true,
})

const buildShowcaseClickedEvent = ({
  showcase,
  listingId: id,
  listingPosition,
  pagination,
  refPage,
}) => {
  const source = localStorage.getSource()

  return {
    showcase: {
      showcase,
      listingId: id,
      listingPosition,
      pagination,
      source,
      refPage,
    },
    event: 'showcaseClicked',
    _clear: true,
  }
}

function buildFromListingsIds (causes, listingId) {
  if (causes) {
    return causes.map((item) => item.id.toString()).filter(Boolean)
  }
  if (listingId) {
    return [listingId.toString()]
  }
  return []
}

export default {
  _getActiveListings ({ recommendations, carousel }) {
    return recommendations.slice(
      (carousel.currentPage - 1) * carousel.itemsPerPage,
      carousel.currentPage * carousel.itemsPerPage,
    ).map((item) => item.listing.listing.id)
  },

  _getPagination ({ recommendations = [], carousel = {} }) {
    const listingIds = this._getActiveListings({ recommendations, carousel }) || []

    const currentPage = (carousel.currentPage) || 1
    const listingsCount = carousel.itemsCount
    const pageResults = Number(carousel.itemsPerPage) || listingIds.length
    const pagesCount = carousel.numberOfPages || 1

    return {
      pagination: {
        currentPage,
        listingsCount,
        pageResults,
        pagesCount,
      },

      listingIds,
    }
  },

  _getShowcase ({
    recommender,
    screen,
    version,
    causes,
    listingId,
  }) {
    const fromListingIds = buildFromListingsIds(causes, listingId)

    return {
      fromListingIds,
      recommender,
      screen,
      version,
    }
  },

  showcaseRendered ({
    data: {
      scores: recommendations,
      version = '',
      recommender,
      causes,
    },
    screen,
    carousel,
    listingId,
    actions,
  }) {
    const { listingIds, pagination } = this._getPagination({ recommendations, carousel })
    const showcase = this._getShowcase({
      version,
      screen,
      recommender,
      causes,
      listingId,
      actions,
    })
    const event = buildShowcaseRenderedEvent({
      actions,
      showcase,
      listingIds,
      pagination,
    })
    Vue.$clickstream.trackEvent(event)
  },

  showcaseClicked ({
    data: {
      scores: recommendations,
      version = '',
      recommender,
      causes,
    },
    screen,
    carousel,
    listingId,
    id,
    position: listingPosition,
    refPage,
  }) {
    const referencePage = refPage ?? trackingPage.REF_PAGE_NO_TRACKING
    const showcase = this._getShowcase({
      version,
      screen,
      recommender,
      causes,
      listingId,
    })
    const { pagination } = this._getPagination({ recommendations, carousel })
    const event = buildShowcaseClickedEvent({
      showcase,
      listingId: id,
      listingPosition,
      pagination,
      refPage: referencePage,
    })
    const eventWithBaseSchema = {
      ...event,
    }

    Vue.$clickstream.trackEvent(eventWithBaseSchema)
  },

  recommendationsPreTracking ({
    listing = {},
    recommendations = [],
    screen = '',
    settings = recommendationsDefaultSettings,
    currentPlatform,
  }) {
    const getActiveListings = (recommendation, index) => {
      const settingsKey = settings.isLargeInstances.includes(index) ? 'isLarge' : 'default'
      const { slidesPerView: perPage } = settings[settingsKey][currentPlatform]

      // get first page scores
      const scores = recommendation.scores.slice(0, perPage)

      // Get listing ids from recommendations
      // TODO: Check if there's a way to remove this deep nesting
      const listingIds = scores.map((score) => (
        score.listing.listing.id
      ))

      return { listingIds, perPage }
    }

    recommendations.forEach((recommendation, index) => {
      const scores = recommendation.scores ?? []
      if (!scores.length) {
        return
      }

      const { listingIds, perPage } = getActiveListings(recommendation, index)
      const { recommender, version } = recommendation

      const event = buildShowcaseRenderedEvent({
        actions: ['RENDERED'],
        listingIds,
        pagination: {
          currentPage: 1,
          listingsCount: scores.length,
          pageResults: perPage,
          pagesCount: (perPage && Math.ceil(scores.length / perPage)) || 0,
        },
        showcase: {
          fromListingIds: buildFromListingsIds(recommendation?.causes, listing?.id),
          recommender,
          screen,
          version,
        },
      })
      Vue.$clickstream.trackEvent(event)
    })
  },
}

export { buildShowcaseRenderedEvent, buildShowcaseClickedEvent }
