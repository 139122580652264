// because of card box-shadow work-arround
// spacing values has be the subtracked by 16
export default {
  isLargeInstances: [0],
  preTracking: true,
  isLarge: {
    mobile: {
      slidesPerView: 1,
    },
    tablet: {
      slidesPerView: 2,
    },
    desktop: {
      slidesPerView: 2,
    },
    carouselConfig: {
      slidesPerView: 1,
      spacing: 0,
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 2,
          spacing: 8,
        },
        '(min-width: 1278px)': {
          slidesPerView: 2,
          spacing: 16,
        },
      },
    },
  },
  default: {
    mobile: {
      slidesPerView: 1,
    },
    tablet: {
      slidesPerView: 3,
    },
    desktop: {
      slidesPerView: 4,
    },
    carouselConfig: {
      slidesPerView: 1,
      spacing: 0,
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 3,
          spacing: 0,
        },
        '(min-width: 1278px)': {
          slidesPerView: 4,
          spacing: 16,
        },
      },
    },
  },
}
