import { mapGetters, mapState } from 'vuex'

import ozLeadPhone from '@/components/lead/oz-lead-phone'

import { modal } from '@/utils/constants/tracking/results/lead-clicked'

import tracker from '@/utils/tracking/lead/lead-panel-clicked'
import LEAD_TYPE from '@/utils/constants/tracking/lead/types'

export default {
  name: 'oz-card-modal-cta',

  props: {
    listing: { type: Object, required: true },
    glossary: { type: Object, required: true },
    publisher: { type: Object, required: true },
    isVirtualTourRequest: { type: Boolean },
    positionRanking: { type: Number },
    listingPosition: { type: Number },
    currentPage: { type: Number },
    shouldRenderDynamicTitle: { type: Boolean, default: false },
    isLeadPhoneModalVariation: { type: Boolean },
    recommendation: { type: Boolean, default: false },
    hideQualityStars: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['messageClick'],

  computed: {
    ...mapState({
      isLogged: (state) => state.auth.authenticated,
    }),

    ...mapGetters({
      leadModal: 'ui/modals/leadModal',
      leadUser: 'leads/leadUser',
      postLeadModal: 'ui/modals/postLeadModal',
      showWhatsappFormModal: 'ui/modals/showWhatsappFormModal',
      isDesktop: 'isDesktop',
    }),

    hasAcceptTerms () {
      return this.leadUser.acceptTerms
    },

    cardModalCtaClass () {
      return {
        'card-modal-cta': true,
        'card-modal-cta--dynamic': this.shouldRenderDynamicTitle,
      }
    },

    ctaPhoneText () {
      return this.isDesktop ? 'Telefone' : 'Ligar'
    },

    showPhoneCta () {
      return this.listing.showPhoneButton
    },

    showCtaTestVariant () {
      if (this.recommendation) return false
      return (this.isLeadPhoneModalVariation && !this.isLogged)
        || (!this.hasAcceptTerms && this.isLogged)
    },

    trackingData () {
      return {
        ...modal.listingCard,
        listingPosition: this.listingPosition,
        phone: this.recommendation
          ? modal.listingCard.recommendationPhoneOneClick
          : modal.listingCard.phoneOneClick,
        pagination: {
          currentPage: this.currentPage,
          positionRanking: this.positionRanking,
        },
      }
    },
  },

  methods: {
    async openLeadModal (isPhoneLead) {
      this.$store.commit('ui/modals/setLeadModal', {
        leadModal: {
          type: isPhoneLead ? 'PHONE' : 'EMAIL',
          listing: this.listing,
          publisher: this.publisher,
          show: true,
          listingId: this.listing.id,
          isVirtualTourRequest: this.isVirtualTourRequest,
          trackingData: this.trackingData,
          leadPhoneModalTest: {
            isLeadPhoneModalVariation: this.isLeadPhoneModalVariation,
            isResultPage: true,
          },
        },
      })

      tracker.leadPanelClicked({
        listingId: this.listing.id,
        senseOfUrgency: this.listing.listingSummary?.leads ?? '',
        subject: isPhoneLead ? this.trackingData.phone.subject : this.trackingData.email.subject,
        screen: isPhoneLead ? this.trackingData.phone.screen : this.trackingData.email.screen,
      })
    },
    /**
     * When you click on the CTA that comes from the WhatsApp modal,
     * the drawer overlaps this modal.
     * This function aims to close the WhatsApp modal before opening the deduplication drawer.
     */
    closeWhatsappModal () {
      if (this.recommendation
        && this.postLeadModal.show
        && this.postLeadModal.type === LEAD_TYPE.WHATSAPP) {
        this.$store.commit('ui/modals/setPostLeadModal', { postLeadModal: { type: '', show: false } })
      }
    },
    stopPropagation (event) {
      event.stopPropagation()
    },
    async onClickMessage (event) {
      this.closeWhatsappModal()

      if (!this.recommendation) {
        this.openLeadModal(false)

        return
      }

      event.preventDefault()
      event.stopPropagation()

      this.$emit('messageClick', event)
    },
  },

  components: {
    ozLeadPhone,
  },
}
