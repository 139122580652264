import { UNIT_TYPES } from '@/utils/constants'
import unitTypeMixin from '@/mixins/unit-types'

export default {
  name: 'new-unit-type',

  props: {
    glossary: { type: Object, required: true },
    unitTypes: { type: [Object, String], default: UNIT_TYPES.UNIT_TYPE_DEFAULT },
    listingType: { type: String, required: true },
  },

  mixins: [unitTypeMixin],

  computed: {
    dropdownCounter () {
      if (this.allSelected) return null

      const total = this.selectedOptions.length
      const amount = total - 1

      if (total > 1) return `+${amount}`
      return null
    },

    dropdownValue () {
      if (this.selectedOptions.length) {
        const { label } = this.options.find((i) => i.value === this.selectedOptions[0])
        return label
      }

      return 'Todos os imóveis'
    },
  },
}
