import { buildImageDescription } from '@/utils/listing'

const getImageDescription = {
  props: {
    link: { type: Object, required: true },
  },
  computed: {
    $getImageDescription () {
      const { link } = this
      return buildImageDescription(link)
    },
  },
}
export default getImageDescription
