import { getRecommendations } from '@/api/recommendations'
import transformerRecommendations from '@/utils/transformers/recommendations'
import { getAnonymousId } from '@/store/auth/selector'

const COUNTRY_CODE_DEFAULT = 'BR'
const IP_LOCATION_TTL_MS = 4 * 60 * 60 * 1000 // 4 hours
const NATIVE_LOCATION_TTL_MS = 15 * 60 * 60 * 24 * 1000 // 15 days
const NATIVE_LOCATION_REFRESH_MS = 30 * 60 * 1000 // 30 minutes
const FALLBACK_LOCATION = {
  latitude: -23.5505632,
  longitude: -46.6343399,
}
const MAXIMUM_AGE_MS = 5 * 60 * 1000 // 5 minutes
const ASKING_LOCATION_DELAY_MS = 5000 // 5s

const fetchByIpLocation = async (store, timestamp, expires) => {
  let location
  try {
    const {
      latitude, longitude, country_code: countryCode,
    } = await store.dispatch('userLocation/setUserLocation', { ipOnly: true }, { root: true })

    location = (countryCode && countryCode.toUpperCase() !== COUNTRY_CODE_DEFAULT)
      ? FALLBACK_LOCATION
      : { latitude, longitude }
  } catch (e) {
    location = FALLBACK_LOCATION
  }
  store.commit('setLocation', { ...location, timestamp, expires })
}

const fetchByNativeLocation = async (store, timestamp, expires) => {
  const success = ({ latitude, longitude }) => {
    store.commit('setLocation', {
      latitude,
      longitude,
      timestamp,
      expires,
    })
  }
  const options = {
    maximumAge: MAXIMUM_AGE_MS,
  }
  const target = () => {
    store.dispatch('userLocation/setUserLocation', { ipFallback: false, options }, { root: true })
      .then((data) => success(data))
      .catch(Boolean)
  }
  // avoid asking the location too early while we have multiple a/b tests and redirects running
  setTimeout(target, ASKING_LOCATION_DELAY_MS)
}

const fetchUserLocation = async (store) => {
  const { location: previousLocation } = store.state
  const { timestamp = 0, expires = 0 } = previousLocation || {}
  const now = Date.now()

  const isExpired = now >= expires
  const isIpReloading = !previousLocation
  const isNativeReloading = now >= timestamp + NATIVE_LOCATION_REFRESH_MS

  if (isExpired || isIpReloading) {
    await fetchByIpLocation(store, now, now + IP_LOCATION_TTL_MS)
  }

  if (isExpired || isNativeReloading) {
    await fetchByNativeLocation(store, now, now + NATIVE_LOCATION_TTL_MS)
  }
}

const fetchRecommendations = async (store, params) => {
  store.commit('setLoading', true)
  try {
    const { simplifiedPlatform: platform, glueEndpoint } = store.rootGetters
    const { domain } = store.rootState.context
    const { latitude, longitude } = store.state.location
    const location = `${latitude},${longitude}`

    const qs = {
      user: getAnonymousId(store.rootState),
      location,
      platform,
      domain,
      images: 'webp',
      ...params,
    }

    const { data } = await getRecommendations(glueEndpoint, qs)
    store.commit('setRecommendations', transformerRecommendations(data))
  } catch (e) {
    store.commit('setRecommendations', null)
  } finally {
    store.commit('setLoading', false)
  }
}

export default {
  fetchUserLocation,
  fetchRecommendations,
}
