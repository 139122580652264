import ozSeoWidgetsGroup from '../oz-seo-widgets-group'

const NOT_ALLOWED_WIDGETS = ['ADVERTISERS_AVAILABLE', 'CONTENT_FROM_LOCATION', 'NEARBY']

export default {
  name: 'oz-seo-widgets-links',

  props: {
    widgets: { type: [Array, Object], required: true },
    minLinksToShow: { type: Number, default: 3 },
  },

  computed: {
    seoWidgets () {
      return [].concat(this.widgets).filter((item = {}) => {
        const { category = '', links = [] } = item
        return !NOT_ALLOWED_WIDGETS.includes(category) && links.length
      })
    },

  },

  components: {
    ozSeoWidgetsGroup,
  },
}
