import Vue from 'vue'
import localStorage from '@/storage/local-storage'

const buildFavoriteClickedEvent = ({
  action,
  listing,
  status,
  subject,
}) => {
  const { id: listingId, pricingInfos } = listing
  const businessTypeContext = localStorage.getBusinessTypeContext() || pricingInfos[0].businessType

  return {
    favorite: {
      action,
      businessTypeContext,
      listingId,
      status,
      subject,
    },
    event: 'favoriteClicked',
  }
}

export default {
  favoriteClicked (data) {
    const event = buildFavoriteClickedEvent(data)
    Vue.$clickstream.trackEvent(event)
  },
}

// Tests
export {
  buildFavoriteClickedEvent,
}
