import { render, staticRenderFns } from "./oz-seo-widgets-neighborhoods.html?vue&type=template&id=7d86b206&"
import script from "./oz-seo-widgets-neighborhoods.js?vue&type=script&lang=js&"
export * from "./oz-seo-widgets-neighborhoods.js?vue&type=script&lang=js&"
import style0 from "./oz-seo-widgets-neighborhoods.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports