import { mapGetters, mapState } from 'vuex'
import { getAddressResume } from '@/filters/address'
import getUrl from '@/mixins/get-url'
import getImageDescription from '@/mixins/get-image-description'

import ozBaseAmenities from '@/components/oz-base-amenities'
import ozFavorite from '@/components/oz-favorite'

import ozCardPrices from '../oz-card-prices'
import ozCardImage from '../oz-card-image'
import ozCardModalCta from '../oz-card-modal-cta'

import {
  CONTENT_GUTTERS_MAPPER,
  IMAGE_SIZES_MAPPER,
  ITL_PARAMS,
} from './constants'

import { ShowingDuplicatesScreen } from '@/store/listing-deduplication'

function listingDuplicateAdapter ({ listing, account }) {
  return {
    ...listing,
    publisher: account,
  }
}

export default {
  name: 'oz-card-listing-compact',

  mixins: [
    getUrl,
    getImageDescription,
  ],

  props: {
    listing: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
    // for tracking purposes
    position: { type: Number, default: 0 },
    screen: { type: String, default: '' },
    recommender: { type: String, default: '' },
    rel: { type: String, default: '' },
    preloadFirstImg: { type: Boolean, default: false },
    isRecommendationCard: { type: Boolean, default: false },
    variant: { type: String, default: 'regular' },
  },

  computed: {
    ...mapState({
      glossary: (state) => state.context.glossary,
    }),
    ...mapGetters(['isMobile', 'currentPlatform']),
    url () {
      return this.isRecommendationCard
        ? `${this.listing.link.href}?itl_id=${this.getItlIdParam(this.recommender)}&itl_name=zap_-_recommendations-container_${this.recommender}_to_zap_pagina-produto`
        : this.listing.link.href
    },
    listingAmenities () {
      return this.listing.listing
    },
    pricingInfo () {
      const { pricingInfo = {} } = this.listingAmenities
      return pricingInfo
    },
    address () {
      const { address } = this.listingAmenities
      return getAddressResume({ address })
    },
    cardDirection () {
      const { size } = this
      return {
        small: 'column',
        regular: 'column',
        large: {
          column: ['xs', 'sm', 'md', 'lg'],
          row: ['xl'],
        },
      }[size]
    },
    guttersContent () {
      const { size } = this
      return {
        small: CONTENT_GUTTERS_MAPPER.SMALL.DEFAULT,
        regular: CONTENT_GUTTERS_MAPPER.REGULAR.DEFAULT,
        large: CONTENT_GUTTERS_MAPPER.LARGE.DEFAULT,
      }[size]
    },
    imageSize () {
      const { size } = this
      return IMAGE_SIZES_MAPPER[size.toUpperCase()][this.currentPlatform]
    },
    isSmallCard () {
      return this.size === 'small'
    },
    isLargeCard () {
      return this.size === 'large'
    },
    textEllipsis () {
      return {
        ellipsis: this.isMobile || this.isSmallCard,
      }
    },
  },

  methods: {
    onCardClick () {
      this.$emit('card-listing:click', {
        ...this.listingAmenities,
        position: this.position,
        screen: this.screen,
      })
    },

    async onMessageClick () {
      this.$store.dispatch('listingDeduplication/writeMessage', {
        listing: listingDuplicateAdapter(this.listing),
        screen: ShowingDuplicatesScreen.RecommendationCard,
      })
    },
    getItlIdParam (recommender) {
      if (recommender && recommender in ITL_PARAMS) {
        return ITL_PARAMS[recommender].itl_id
      }
      return ITL_PARAMS.undefined.itl_id
    },
  },

  components: {
    ozCardModalCta,
    ozBaseAmenities,
    ozCardPrices,
    ozCardImage,
    ozFavorite,
  },
}
