import cardPricesMixin from '@/mixins/card-prices'

export default {
  name: 'oz-card-prices',

  mixins: [cardPricesMixin],

  props: {
    businessTypeContext: { type: String },
    showAllPrices: { type: Boolean, default: true },
    size: { type: String, default: 'regular' },
    textSize: { type: String, default: 'regular' },
    pricingInfoGlossary: { type: Object, default: () => { } },
    datazapApproved: { type: Boolean, default: false },
    unitTypeDatazap: { type: Boolean },
    accountInfo: { type: String },
    rentalDatazapApproved: { type: Boolean, default: false },
    datazapRangePositionName: { type: String },
    datazapPage: { type: Function },
    shouldRenderDynamicTitle: { type: Boolean, default: false },
  },

  computed: {
    cardPricesClass () {
      return {
        'simple-card__prices': true,
        'card-prices--dynamic': this.shouldRenderDynamicTitle,
      }
    },

    prices () {
      if (!this.businessTypeContext) return this.pricingInfo

      const { priceMap } = this.pricingInfo

      return priceMap[this.businessTypeContext]
    },

    glossary () {
      return {
        yearlyIptu: 'IPTU',
        monthlyCondoFee: 'condomínio',
        ...this.pricingInfoGlossary,
      }
    },

    headingColor () {
      return this.datazapApproved || this.rentalDatazapApproved ? 'primary' : 'darker'
    },
  },
}
