import clickTracker from '@/utils/tracking/neighborhoods-guide/neighborhoods-guide-link-clicked'
import viewTracker from '@/utils/tracking/neighborhoods-guide/neighborhoods-guide-link-view'
import { POSITIONS } from '@/utils/constants'

export default {
  name: 'oz-seo-widgets-neighborhoods',

  props: {
    neighborhoodsStates: { type: [Array], required: true },
  },

  data () {
    return {
      observeVisibilityConfig: {
        callback: this.trackOnVisible,
        once: true,
        intersection: {
          threshold: 0.25,
        },
      },
      title: 'Explore por bairros mais populares do Brasil',
    }
  },

  methods: {
    trackingData (name) {
      return {
        label: name,
        position: POSITIONS.WIDGET,
      }
    },
    trackOnClick (name) {
      const { label, position } = this.trackingData(name)
      clickTracker.linkClicked(label, position)
    },
    trackOnVisible (isVisible) {
      if (!isVisible) return
      const { label, position } = this.trackingData(this.title)
      viewTracker.sectionViewed(label, position)
    },
  },

  filters: {
    formatStateTitle (state) {
      return `Bairros de ${state}`
    },
  },
}
