import apiBase from './base'
import apiClient from './client'

const FIELDS = {
  size: 6,
  portal: 'ZAP',
  includeFields: 'address.street,address.neighborhood,address.city,address.state,address.zone,address.locationId,address.point,url,advertiser.name,advertiser.id,uriCategory.page,condominium.name',
  fields: 'neighborhood,city,street,account,condominium',
}

export default {
  async get (store, {
    listingType, unitTypes, addressCity = '', addressState = '',
  }, query, businessType) {
    const types = typeof unitTypes === 'string' ? { unitTypes } : unitTypes

    const queryParams = new URLSearchParams({
      ...FIELDS,
      ...types,
      businessType,
      listingType,
      addressCity,
      addressState,
      q: query,
    })

    return apiClient.get(`${apiBase.getGlueUrl(store)}/v3/locations/?${queryParams}`)
  },
}
