import { mapState } from 'vuex'

import { UNIT_TYPES } from '@/utils/constants'

import {
  getTypes,
  getTypesValues,
} from '@/utils/transformers/filters/unit-types'

const unitTypes = {
  data () {
    return {
      allSelected: false,
      selectedGroup: [],
      selectedOptions: [],
      options: [],
      groups: [],
      data: {},
    }
  },

  created () {
    this.data = this.$_unit_types_options()
    this.groups = Object.keys(this.data)

    this.groups.forEach((group) => (
      this.data[group].items.forEach((item) => {
        this.options.push({ group, ...item })
      })
    ))
  },

  mounted () {
    this.$_unit_types_select()
  },

  computed: {
    ...mapState({
      unitTypeGlossary: (state) => state.context.unitTypeGlossary,
    }),
  },

  methods: {
    $_unit_types_emit (values = []) {
      const labels = []

      values.forEach((value) => {
        const item = this.options.find((i) => i.value === value)
        if (item) {
          labels.push(item.label)
        }
      })

      this.$emit('selected:types', {
        unitTypes: getTypes(values) || UNIT_TYPES.UNIT_TYPE_DEFAULT,
        labels,
      })
    },

    $_unit_types_select () {
      if (this.unitTypes === UNIT_TYPES.UNIT_TYPE_DEFAULT) return

      const values = getTypesValues(this.unitTypes)

      this.selectedGroup = this.$_unit_types_groups(values)
      this.selectedOptions = values
    },

    $_unit_types_select_all (val) {
      if (!val) {
        this.selectedOptions = []
        this.selectedGroup = []

        return
      }

      this.selectedGroup = this.groups
      this.selectedOptions = this.options.map((i) => i.value)

      this.$_unit_types_emit()
    },

    $_unit_types_select_group (groups) {
      if (groups.length === this.groups.length) {
        this.allSelected = true
        this.$_unit_types_select_all(true)

        return
      }

      const values = []

      groups.forEach((group) => {
        values.push(...this.data[group].items.map((i) => i.value))
      })

      this.allSelected = false
      this.selectedOptions = values.length ? values : []

      this.$_unit_types_emit(values)
    },

    $_unit_types_select_item (items) {
      this.selectedGroup = this.$_unit_types_groups(items)
      this.allSelected = this.selectedOptions.length === this.options.length

      this.$_unit_types_emit(items)
    },

    $_unit_types_groups (values) {
      const groups = []

      Object.keys(this.data).forEach((group) => {
        if (this.data[group].items.every((i) => values.find((v) => v === i.value))) {
          groups.push(group)
        }
      })

      return groups
    },

    $_unit_types_options () {
      return Object.keys(this.unitTypeGlossary).reduce((acc, curr) => {
        const { group, items } = this.unitTypeGlossary[curr]

        acc[curr] = {
          group,
          items: items.map((i) => ({ label: i.text, value: getTypesValues(i)[0] })),
        }

        return acc
      }, {})
    },
  },
}

export default unitTypes
