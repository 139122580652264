import { icVerified } from '@vivareal/lina-icons'

export default {
  name: 'oz-datazap-stamp',

  props: {
    ignoredClass: { type: Boolean, default: false },
    showStamp: { type: Boolean },
    tooltipClass: { type: String, default: '' },
    shouldRenderDynamicTitle: { type: Boolean, default: false },
  },

  data () {
    return {
      icons: { icVerified },
    }
  },

  computed: {
    stampClass () {
      return {
        'oz-datazap-stamp': true,
        'oz-datazap-stamp--approved': this.showStamp && !this.ignoredClass,
        'oz-datazap-stamp--dynamic': this.shouldRenderDynamicTitle,
      }
    },
  },
}
