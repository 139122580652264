import Vue from 'vue'

const buidGalleryClickedEvent = ({
  listing: {
    id: listingId,
    images: { length: photosCount },
  },
  action,
  screen,
  currentPhotoPosition,
  subject,
}) => ({
  gallery: {
    action,
    currentPhotoPosition,
    listingId,
    photosCount,
    screen,
    subject,
  },
  event: 'galleryClicked',
  _clear: true,
})

export default {
  reset () {
    Object.keys(this.trackOncePositions)
      .forEach((key) => { this.trackOncePositions[key] = [] })
  },
  trackOncePositions: {
    'SWIPE HERO GALLERY': [],
    'SWIPE FULL SCREEN GALLERY': [],
  },
  galleryClicked (data) {
    const {
      action,
      screen: rawScreen,
      subject,
      current,
    } = data
    const currentPhotoPosition = Number(current) + 1
    const screen = `${rawScreen} GALLERY`

    const tracking = [action, screen, subject].join(' ').trim()
    const trackOnce = this.trackOncePositions[tracking]
    if (trackOnce) {
      if (trackOnce.includes(currentPhotoPosition)) return
      trackOnce.push(currentPhotoPosition)
    }
    const event = buidGalleryClickedEvent({
      ...data,
      currentPhotoPosition,
      screen,
    })
    Vue.$clickstream.trackEvent(event)
  },
}
// Tests
export { buidGalleryClickedEvent }
