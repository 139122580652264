import { marked } from 'marked'
import tracker from './oz-interests-showcase.tracker'

export default {
  name: 'oz-interests-showcase',

  props: {
    interests: {
      type: Array,
      required: true,
    },
  },

  methods: {
    track ({ subject, linkReference }) {
      tracker.interestShowcaseClicked({ subject, link: linkReference })
    },

    convertMarkDown (markdownText) {
      return marked(this.insertLineBreak(markdownText))
    },

    insertLineBreak (markdownText) {
      return markdownText.replace(/\n/g, '<br>')
    },
  },
}
