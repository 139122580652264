import LEAD_INTENT from '../lead/intent'
import LEAD_SUBJECT from '../lead/subjects'
import LEAD_SCREEN from '../lead/screen'

export const modal = {
  listingCard: {
    phone: {
      intent: LEAD_INTENT.SEE_PHONE,
      screen: LEAD_SCREEN.MODAL_FORM,
      subject: LEAD_SUBJECT.LEAD_CLICKED.LISTING_CARD,
    },
    phoneOneClick: {
      intent: LEAD_INTENT.SEE_PHONE,
      screen: LEAD_SCREEN.PHONE_ACCEPTED_TERMS,
      subject: LEAD_SUBJECT.LEAD_CLICKED.LISTING_CARD_ONE_CLICK_TO_LEAD,
    },
    recommendationPhoneOneClick: {
      intent: LEAD_INTENT.SEE_PHONE,
      screen: LEAD_SCREEN.SHOWCASE,
      subject: LEAD_SUBJECT.LEAD_CLICKED.POST_LEAD,
    },
    email: {
      intent: LEAD_INTENT.ANSWERS_REQUEST,
      screen: LEAD_SCREEN.MODAL_FORM,
      subject: LEAD_SUBJECT.LEAD_CLICKED.LISTING_CARD,
    },
    recommendationEmail: {
      intent: LEAD_INTENT.ANSWERS_REQUEST,
      screen: LEAD_SCREEN.SHOWCASE,
      subject: LEAD_SUBJECT.LEAD_CLICKED.POST_LEAD,
    },
  },
  defaulterAdvertiser: {
    phone: {
      intent: LEAD_INTENT.SEE_PHONE,
      screen: LEAD_SCREEN.DEFAULTER_ADVERTISER_FORM,
      subject: LEAD_SUBJECT.LEAD_CLICKED.DEFAULTER_ADVERTISER_FORM,
    },
    email: {
      intent: LEAD_INTENT.ANSWERS_REQUEST,
      screen: LEAD_SCREEN.DEFAULTER_ADVERTISER_FORM,
      subject: LEAD_SUBJECT.LEAD_CLICKED.DEFAULTER_ADVERTISER_FORM,
    },
  },
  mapListingCard: {
    email: {
      intent: LEAD_INTENT.ANSWERS_REQUEST,
      screen: LEAD_SCREEN.MODAL_FORM,
      subject: LEAD_SUBJECT.LEAD_CLICKED.MAP_LISTING_CARD,
    },
    phoneOneClick: {
      intent: LEAD_INTENT.SEE_PHONE,
      screen: LEAD_SCREEN.PHONE_ACCEPTED_TERMS,
      subject: LEAD_SUBJECT.LEAD_CLICKED.MAP_LISTING_CARD,
    },
    phone: {
      intent: LEAD_INTENT.SEE_PHONE,
      screen: LEAD_SCREEN.MODAL_FORM,
      subject: LEAD_SUBJECT.LEAD_CLICKED.MAP_LISTING_CARD,
    },
  },
}

export default {}
