const setLocation = (state, {
  latitude,
  longitude,
  timestamp,
  expires,
}) => {
  const location = {
    latitude: (parseInt(latitude * 1e4, 10) / 1e4),
    longitude: (parseInt(longitude * 1e4, 10) / 1e4),
    timestamp,
    expires,
  }
  Object.assign(state, { location })
}

const setRecommendations = (state, recommendations) => {
  Object.assign(state, { recommendations })
}

const setLoading = (state, loading) => {
  Object.assign(state, { loading })
}

export default {
  setLocation,
  setRecommendations,
  setLoading,
}
