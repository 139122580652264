import { mapGetters } from 'vuex'

const getUrl = {
  computed: {
    ...mapGetters(['safeQuery']),
    $getUrl () {
      return {
        path: this.url,
        query: this.safeQuery,
      }
    },
  },
}

export default getUrl
