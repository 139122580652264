const formatPhone = (value = '') => {
  const phone = value.toString().slice(0, 11).replace(/^0+/, '').replace(/[^0-9]/g, '')
  const { length } = phone

  if (length <= 6) return phone.replace(/(\d{2})(\d{4,5})?/, '($1) $2')
  if (length <= 10) return phone.replace(/(\d{2})(\d{4})(\d{1,4})/, '($1) $2-$3')
  return phone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
}

export default formatPhone
