import { defineComponent } from 'vue'
import tracker from '@/components/seo/oz-seo-widgets-group/oz-seo-widgets-group.tracker'
import { ITL_PARAMS } from './constants'
import './oz-seo-widgets-group.scss'

export default defineComponent({
  name: 'oz-seo-widgets-group',

  props: {
    titleClass: {
      type: String,
    },
    widget: {
      type: Object,
      required: true,
    },
    fixed: {
      type: Number,
      // it must be multiple of 3 and 2 simultaneously
      default: 30,
    },
  },

  data () {
    return {
      opened: false,
    }
  },

  render () {
    const renderItems = (items) => items.map((link, index) => (
        <li class="seo-widgets-group__item" key={link.href} data-index={index}>
          <l-link
            href={`${link.href}?itl_id=${this.getItlIdParam(this.widget.category?.toLowerCase())}&itl_name=zap_-_seo-widgets-group_${this.widget.category?.toLowerCase()}_to_zap_resultado-pesquisa`}
            size="small"
            weight="normal"
            color="dark"
            class="seo-widgets-group__link"
            onClick={() => this.trackOnClick(link, this.widget.category)}
          >
            { link.name }
          </l-link>
        </li>
    ))

    let content = <div class="seo-widgets-group__grid">{renderItems(this.widget.links.slice(0, this.fixed))}</div>
    const collapsible = renderItems(this.widget.links.slice(this.fixed))

    if (collapsible.length) {
      content = <new-l-collapse
        isOpen={true}
        hasIcon={true}
        actionComponent="button"
        context="secondary"
        onActionClick={this.handleCollapse}
      >
        {content}
        <div class={['seo-widgets-group__grid seo-widgets-group__grid-collapsible', { collapsed: !this.opened }]}>{collapsible}</div>
      </new-l-collapse>
    }

    return <div
      class="seo-widgets-group"
      category={this.widget.category}
    >
      <l-heading
        tag={this.widget.heading ?? 'h2'}
        class={['seo-widgets-group__title', this.titleClass]}
        weight="bold"
      >
        {this.widget.header.name}
      </l-heading>

      <ul class="seo-widgets-group__content">{content}</ul>
    </div>
  },

  methods: {
    trackOnExpand (opened) {
      if (opened) tracker.expandLinksClicked()
    },
    trackOnClick (link, category) {
      const { name } = link
      tracker.linkClicked(category, name)
    },
    handleCollapse (opened) {
      this.opened = opened
      this.trackOnExpand(opened)
    },
    getItlIdParam (category) {
      if (category && category in ITL_PARAMS) {
        return ITL_PARAMS[category].itl_id
      }
      return ITL_PARAMS.undefined.itl_id
    },
  },
})
