import {
  icNewTab, icSearch, icInfo,
} from '@vivareal/lina-icons'
import locationsSuggestions from '@/mixins/locations-suggestions'

const ozLoading = () => import(/* webpackChunkName: "oz-loading" */ '@/components/oz-loading')

export default {
  name: 'new-address-typeahead',

  mixins: [locationsSuggestions],

  props: {
    delay: {
      type: Number,
      default: 500,
    },
    minQuery: {
      type: Number,
      default: 3,
    },
    filters: {
      type: Object,
      required: true,
    },
    context: {
      type: String,
      default: 'home',
      validator: (value) => ['home', 'results'].includes(value),
    },
    messageAddressNotFound: {
      type: String,
      default: 'Não há resultados para esta localização',
    },
  },

  data () {
    return {
      label: 'Onde?',
      placeholder: 'Digite um Bairro, Cidade ou Rua',
      checkedLocations: [],
      inputValueLength: 0,
      showWarningBox: false,
      query: '',
      icons: {
        icSearch,
        icNewTab,
        icInfo,
      },
    }
  },

  computed: {
    placeholderText () {
      return (this.suggestionsSaved[0] && this.suggestionsSaved[0].text) || this.placeholder
    },

    suggestions () {
      return this.limitSearch ? this.locations.filter((i) => ['street', 'neighborhood', 'city'].includes(i.group.name)) : []
    },

    suggestionsLink () {
      return this.limitSearch ? this.locations.filter((i) => ['account', 'condominium'].includes(i.group.name)) : []
    },

    suggestionsSaved () {
      return this.savedLocations || []
    },

    searching () {
      return this.query.length > this.minQuery
      && !(this.suggestions.length > 0 || this.suggestionsLink.length > 0)
      && !this.showWarningBox
    },

    buttonCounter () {
      const locations = this.checkedLocations.length
      if (locations === 1) {
        return `${locations}`
      }
      if (locations > 1) {
        const countLocations = locations - 1
        return `+${countLocations}`
      }

      return null
    },

    limitSearch () {
      const LIMIT_SEARCH_LOCATION = 5
      return this.savedLocations.length < LIMIT_SEARCH_LOCATION
    },

    alertMessage () {
      if (!this.limitSearch) {
        return 'Você atingiu o limite de endereços'
      }

      if (this.showWarningBox) {
        return this.messageAddressNotFound
      }

      return ''
    },

    showAlertMessage () {
      return !this.searching && (!this.limitSearch || this.showWarningBox)
    },
  },

  watch: {
    checkedLocations (locations) {
      if (this.checkedLocations.length) {
        this.query = ''
      }
      this.$_autocomplete_emit(locations.map((location) => (JSON.parse(location))))
    },

    locations (newLocations) {
      this.locations = newLocations
      this.shouldShowWarningBox()
    },
  },

  methods: {
    onInput (value) {
      this.inputValueLength = value.length
      this.query = value
      this.$_autocomplete_input()
    },

    linkClicked ({ text, link }) {
      this.$emit('link:clicked', { text, link })
    },

    shouldShowWarningBox () {
      this.showWarningBox = this.locations.length === 0 && this.inputValueLength > this.minQuery
    },
  },
  components: {
    ozLoading,
  },
}
