/* eslint-disable complexity */
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

import ozLabel from '@/components/oz-label'
import carouselTracker from '@/components/oz-carousel/oz-carousel.tracker'

import { SUBJECTS } from '@/utils/constants'

import cardPlaceholder from '@/assets/images/icons/card-placeholder.svg'
import zapWayMaisLogo from '@/assets/logos/brand-zap/zapway-mais.svg'
import zapWayMaisTexto from '@/assets/logos/brand-zap/zapway-mais-texto.svg'
import ozCarousel from '@/components/oz-carousel'

import { icEye } from '@vivareal/lina-icons'
import icDiamond from '@/assets/images/icons/tier-diamond.svg'
import icPlatinum from '@/assets/images/icons/tier-platinum.svg'

import { TRUNCATE_IMAGES, getVariationOf } from '@/store/experimentations'
import select from '@/utils/select'
import TYPES_MAP from '../constants'

const OzImage = Vue.extend({
  name: 'oz-image',

  props: {
    src: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    loading: { type: String },
    size: { type: String },
    attrs: { type: Object },
  },

  render () {
    const {
      src, size, index, attrs, description,
      loading,
    } = this

    return <l-resized-image
      url={src}
      title={`Imagem ${index + 1} - ${description}`}
      attrs={attrs}
      loading={loading}
      props={{
        alt: `${description} - Foto ${index + 1}`,
        description,
        size,
        crop: true,
      }}
    />
  },
})

export default {
  name: 'oz-card-image',

  props: {
    alt: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    imageWidth: {
      type: [Number, String],
    },
    imageHeight: {
      type: [Number, String],
    },
    carouselActive: {
      type: Boolean,
    },
    listing: {
      type: Object,
      required: true,
    },
    glossary: {
      type: Object,
    },
    account: {
      type: Object,
      default: null,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    doublePhoto: {
      type: Boolean,
      default: false,
    },
    visitedListing: {
      type: Boolean,
      default: false,
    },
    // for tracking purposes
    screen: { type: String, default: '' },

    type: {
      type: String,
      default: 'card',
    },
    listingType: {
      type: String,
      default: '',
    },
    compact: {
      type: Boolean,
      default: false,
    },
    schemaMarkup: {
      type: Boolean,
      default: false,
    },
    preloadFirstImg: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
    },
    isDeduplicationListingCard: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      zapWayMaisLogo,
      zapWayMaisTexto,
      currentPage: 0,
      avatarError: false,
      showImages: false,
      showAvatar: false,
      customIcon: cardPlaceholder,
      renderedImgs: {},
      carousel: {},
    }
  },

  watch: {
    $route () {
      carouselTracker.reset()
    },
  },

  computed: {
    ...mapState({
      featureToggle: (state) => state.context.featureToggle,
      seasonalCampaigns: (state) => state.results.seasonalCampaigns,
    }),

    ...mapGetters(['isMobile']),

    /** if true it'll show the "Ver mais detalhes" after last image  */
    showSeeMore: select(
      getVariationOf(TRUNCATE_IMAGES),
      ([, { showPlaceholder }]) => showPlaceholder,
    ),

    textSizeStamp () {
      return this.isMobile || this.compact ? 'small' : 'large'
    },

    images () {
      return this.listing.images
    },

    cardType () {
      return TYPES_MAP[this.type.toUpperCase()]
    },

    hasAvatar () {
      const { account } = this
      const {
        publicationType,
        avatarError,
      } = this.listing

      if (!account || !account.logoUrl) return false

      return publicationType && publicationType !== 'STANDARD' && !avatarError
    },

    sizeImage () {
      const {
        width,
        height,
      } = this
      return {
        props: {
          width: `${width}px`,
          height: `${height}px`,
        },
      }
    },

    imageWrapperProps () {
      const {
        images,
        showImage,
      } = this
      if (images?.length && showImage) {
        return {
          class: `simple-card__header ${this.isDeduplicationListingCard ? 'simple-card__dedup' : ''}`,
          props: {
            position: 'relative',
            overflow: 'hidden',
          },
        }
      }

      return {
        class: `simple-card__header simple-card__header--empty ${this.isDeduplicationListingCard ? 'simple-card__dedup' : ''}`,
        props: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }
    },

    highlightLabel () {
      if (!this.glossary) return false

      const {
        listing: {
          publicationType, constructionStatus, status,
        },
        glossary,
        listingType,
        account,
      } = this

      const listingSeenNode = () => {
        if (!this.visitedListing) return false
        return (
          <small class="simple-card__highligths--seen-listings">
            <new-l-icon icon={icEye} color="#fff" />
            <new-l-text class="simple-card__highligths--seen-listings--text" tag="span">visualizado</new-l-text>
          </small>
        )
      }
      return (
        <div class="simple-card__highligths">
          <oz-label
            publicationType={listingType === 'rankingSuperPremium' && !account ? '' : publicationType}
            constructionStatus={constructionStatus}
            listingStatus={status}
            glossary={glossary}
            isDeduplicationListingCard={this.isDeduplicationListingCard} />
          {listingSeenNode()}
      </div>)
    },

    isDiamond () {
      return this.listing.publicationType === 'PREMIERE_1'
    },

    tierStripe () {
      const TYPES = [
        {
          type: 'PREMIERE_1',
          text: 'Premium',
          class: 'tier-stripe__diamond',
          icon: icDiamond,
        },
        {
          type: 'PREMIERE_2',
          text: 'Especial',
          class: 'tier-stripe__platinum',
          icon: icPlatinum,
        },
      ]

      const type = TYPES.find((i) => i.type === this.listing.publicationType)

      if (!type) return false

      return (
        <div class={['tier-stripe', type.class]}>
            <img alt={`Ícone ${type.text}`} src={type.icon} />
            <new-l-text class="tier-stripe--text" tag="span">
              Destaque {type.text}
            </new-l-text>
        </div>
      )
    },

    logoProps () {
      const {
        logoUrl,
      } = this.account || {}

      return {
        class: 'simple-card__logo',
        props: {
          size: '64x48',
          alt: '',
          url: logoUrl,
        },
      }
    },

    showImage () {
      const { listing } = this

      return !listing.isDefaulterInactive
    },

    imageSchemaMarkupProp () {
      return this.schemaMarkup ? { itemprop: 'image' } : {}
    },

    hasCampaignStamp () {
      return this.featureToggle.seasonal_campaign_active_viva
        && this.listing.stamps
        && this.seasonalCampaigns
        && this.seasonalCampaigns.some(
          (seasonalCampaign) => this.listing.stamps.includes(seasonalCampaign?.stamp),
        )
    },

    /**
     * @returns {{
     *  alt: string
     *  src: string,
     * }}
     */
    stampImage () {
      const seasonalCampaignsFilter = this.seasonalCampaigns
        .filter(
          (seasonalCampaign) => seasonalCampaign.medias.length
            && this.listing.stamps?.includes(seasonalCampaign.stamp),
        )

      const medias = seasonalCampaignsFilter.flatMap((seasonalCampaign) => seasonalCampaign.medias)

      const stamp = medias.filter((media) => media.category === 'STAMPS')[0]

      const stampSrc = this.isMobile ? stamp?.imageUrlMobile : stamp?.imageUrl

      return {
        src: stampSrc ?? '#',
        alt: seasonalCampaignsFilter[0]?.name ?? '',
      }
    },
  },

  mounted () {
    this.showImages = true
    this.showAvatar = true
  },

  methods: {

    track (current = 0) {
      const { listing, cardType: screen } = this

      carouselTracker.galleryClicked({
        action: 'SWIPE',
        current,
        listing,
        screen,
        subject: SUBJECTS.GALLERY.PHOTO_BUTTON,
      })
    },

    setAvatarError () {
      this.avatarError = true
    },

    getAvatar () {
      const {
        hasAvatar,
        logoProps,
        showAvatar,
      } = this

      return showAvatar
        && hasAvatar
        && (<l-resized-image {...logoProps} />)
    },

    onCarouselPageChange ({
      hasNext, hasPrev, currentPage, currentIndex,
    }) {
      this.carousel = {
        ...this.carousel,
        hasNext,
        hasPrev,
        currentPage,
        currentIndex,
      }

      this.currentPage = currentPage

      if (!this.renderedImgs[currentIndex]) {
        this.renderedImgs[currentIndex] = true
        this.track(currentIndex)
      }
    },

    onCarouselMounted (carousel) {
      this.carousel = carousel
    },

    renderImage (index) {
      const {
        width,
        height,
        imageWidth = width,
        imageHeight = height,
        description,
        position,
        schemaMarkup,
      } = this

      const image = this.images[index]

      return <oz-image
        index={index}
        key={image.url + index}
        src={image.url}
        size={`${imageWidth}x${imageHeight}`}
        attrs={schemaMarkup ? { itemprop: 'image' } : {}}
        loading={position > 0 && index === 0 ? 'lazy' : undefined}
        description={description}
      />
    },

    mountImageNode (imageItem, index, renderImage = false) {
      const slide = (diffKey = '') => (
        <div class="oz-card-image__slide" key={imageItem.url + index + diffKey} data-keen-slider-pe>
          {(renderImage && this.renderImage(index))}
        </div>
      )

      return slide
    },

    mountDoubleImageInNode (images, carouselConfig, id) {
      const primaryImages = []
      const secondaryImages = []

      images.forEach((imgItem, index) => {
        const imageNode = this.mountImageNode(imgItem, index, true)
        const isEven = index % 2 === 0

        // eslint-disable-next-line no-unused-expressions
        isEven ? primaryImages.push(imageNode) : secondaryImages.push(imageNode)
      })

      const equalsLengthArrays = primaryImages.length === secondaryImages.length

      const node = () => (
        <div class="carousel oz-card-image__carousel">
          <oz-carousel class="oz-card-image__slider" {...carouselConfig} key={id}>
            {primaryImages.map((item, index) => {
              const lastIndex = index === primaryImages.length - 1
              const getFirstElementInSecondArray = lastIndex && !equalsLengthArrays
              const indexSecondArray = getFirstElementInSecondArray ? 0 : index
              const diffKeyForLastElem = getFirstElementInSecondArray ? 'repeatFirstImage' : ''

              return (
                <div class="oz-card__double-image" data-keen-slider-pe="true">
                  {[
                    item(),
                    secondaryImages.length
                      && secondaryImages[indexSecondArray](diffKeyForLastElem),
                  ] }
                </div>)
            })}
          </oz-carousel>
      </div>
      )
      return node()
    },
  },

  render () {
    const {
      images,
      carouselActive,
      highlightLabel,
      imageWrapperProps,
      showImage,
      customIcon,
      currentPage,
      sizeImage,
      listing,
      showImages,
      preloadFirstImg,
      renderedImgs,
      doublePhoto,
      hasCampaignStamp,
      stampImage,
      tierStripe,
      showSeeMore,
    } = this

    let displayImages = ''

    if (!images?.length || !showImage) {
      displayImages = <l-image width="48" height="48" src={customIcon} alt="Imagem indisponível" />
    } else if (carouselActive) {
      const carouselConfig = {
        ref: 'imgCarousel',
        loop: showSeeMore,
        props: {
          slidesPerView: doublePhoto ? 2 : 1,
          slideClass: 'oz-card-image__slide',
        },
        on: {
          'carousel:page-change': this.onCarouselPageChange,
          'carousel:mounted': this.onCarouselMounted,
        },
      }

      if (doublePhoto) {
        displayImages = this.mountDoubleImageInNode(images, carouselConfig, listing.id)
      } else {
        displayImages = (
          <div class="carousel oz-card-image__carousel">
            <oz-carousel class="oz-card-image__slider" loop={carouselConfig.loop} key={listing.id} {...carouselConfig}>
              {images.map((imgItem, index) => {
                const canLoadImg = (preloadFirstImg || showImages) && index === 0
                const renderImage = (canLoadImg || renderedImgs[index])
                const imageNode = this.mountImageNode(imgItem, index, renderImage)

                return imageNode()
              })}
              {
                showSeeMore && <div data-keen-slider-pe class="oz-card-image__slide">
                  {this.renderImage(images.length - 1)}
                  <div class="oz-card-image__backdrop">
                    <span class="oz-card-image__see-more">Ver mais detalhes</span>
                  </div>
                </div>
              }
            </oz-carousel>
          </div>
        )
      }
    } else if (images?.length) {
      displayImages = this.renderImage(0)
    }

    const avatar = this.isDiamond && this.getAvatar()

    return (
      <l-box
        {...imageWrapperProps} data-page={currentPage}>
        {displayImages}
        {highlightLabel}
        {avatar}
        <l-box class="stamp__campaign" v-show={hasCampaignStamp && stampImage.src && stampImage.src !== '#'}>
          <l-image
            src={stampImage.src}
            alt={stampImage.alt} class="stamp__campaign-logo"
          />
        </l-box>
        {listing.isAllowedConcierge && (
          <l-box {...sizeImage} class="stamp__concierge">
            <div class="stamp__concierge-brand">
              <l-image src={zapWayMaisLogo} alt="ZAPway+" class="stamp__concierge-brand-logo" />
              <l-image src={zapWayMaisTexto} alt="Aluguel online e sem fiador" class="stamp__concierge-brand-text" />
            </div>
          </l-box>
        )}
        {tierStripe}
      </l-box>
    )
  },

  components: {
    ozLabel,
    ozCarousel,
    OzImage,
  },
}
