import {
  icBuilding,
  icArea,
  icBathroom,
  icBedroom,
  icParking,
} from '@vivareal/lina-icons'

const AREAS_FIELDS = Object.freeze(['totalAreas', 'usableAreas'])

const BASE_AMENITIES = Object.freeze(['usableAreas', 'bedrooms', 'parkingSpaces', 'bathrooms', 'unitFloor'])

const getAmenitiesParams = (isDevelopment) => ({
  usableAreas: {
    feature: isDevelopment ? 'totalAreas' : 'usableAreas',
    fallback: isDevelopment ? 'usableAreas' : 'totalAreas',
    className: 'js-areas',
    icon: icArea,
    singular: 'm²',
  },

  bedrooms: {
    feature: 'bedrooms',
    icon: icBedroom,
    className: 'js-bedrooms',
    singular: 'quarto',
    plural: 'quartos',
  },

  parkingSpaces: {
    feature: 'parkingSpaces',
    icon: icParking,
    className: 'js-parking-spaces',
    singular: 'vaga',
    plural: 'vagas',
  },

  bathrooms: {
    feature: 'bathrooms',
    icon: icBathroom,
    className: 'js-bathrooms',
    singular: 'banheiro',
    plural: 'banheiros',
  },

  unitFloor: {
    feature: 'unitFloor',
    icon: icBuilding,
    className: 'js-floor',
    singular: 'andar',
    isOrdinal: true,
  },
})

const getAmenitiesSchemaMarkup = () => ({
  bathrooms: 'numberOfBathroomsTotal',
  bedrooms: 'numberOfRooms',
  unitFloor: 'floorLevel',
  usableAreas: 'floorSize',
  totalAreas: 'floorSize',
})

export default getAmenitiesParams
export {
  AREAS_FIELDS,
  BASE_AMENITIES,
  getAmenitiesSchemaMarkup,
}
