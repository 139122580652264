export const BUSINESS_TYPES = [
  {
    value: 'SALE',
    label: 'Comprar',
  },
  {
    value: 'RENTAL',
    label: 'Alugar',
  },
]

export const LISTING_TYPES = {
  SALE: 'USED',
  RENTAL: 'USED',
  DEVELOPMENT: 'DEVELOPMENT',
}

export default {
  BUSINESS_TYPES,
  LISTING_TYPES,
}
